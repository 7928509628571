import { ToastContainer } from 'react-toastify';

import useStyles from './styles';

const TooltipContainer = () => {
  const classes = useStyles();

  return (
    <ToastContainer
      position='bottom-center'
      className={classes.tooltipContainer}
    />
  );
};

export default TooltipContainer;
