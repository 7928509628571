import { gql } from '@apollo/client';

export const BLOCK_UNBLOCK_MUTATION = gql`
  mutation blockUnblockUser($id: ID!) {
    blockUnblockUser(id: $id)
  }
`;

export const PROJECT_CONFIRMED_MUTATION = gql`
  mutation projectToStatusConfirmed($id: ID!) {
    projectToStatusConfirmed(id: $id)
  }
`;

export const PROJECT_DELETE_MUTATION = gql`
  mutation deleteProject($projectId: ID!) {
    deleteProject(projectId: $projectId)
  }
`;

export const PROJECT_REJECT_MUTATION = gql`
  mutation rejectProjectChanges($id: ID!) {
    rejectProjectChanges(id: $id)
  }
`;

export const COMPLAINT_DELETE_MUTATION = gql`
  mutation deleteComplaint($id: ID) {
    deleteComplaint(id: $id)
  }
`;

export const DISCONECT_ADMIN_FROM_CHAT = gql`
  mutation disconnectAdminFromChat($id: ID!) {
    disconnectAdminFromChat(id: $id)
  }
`;

export const ADD_ADMIN = gql`
  mutation addAdmin(
    $email: String!
    $password: String!
    $confirmPassword: String!
    $firstname: String
    $lastname: String
    $file: Upload
  ) {
    addAdmin(
      email: $email
      password: $password
      confirmPassword: $confirmPassword
      firstname: $firstname
      lastname: $lastname
      file: $file
    ) {
      status
    }
  }
`;

export const UPDATE_PROJECT_MUTATION = gql`
  mutation createProjectOrUpdate($data: createProjectOrUpdateInput!) {
    createProjectOrUpdate(data: $data) {
      id
    }
  }
`;

export const SEND_MESSAGE_MUTATION = gql`
  mutation sendMessage($chatId: ID, $text: String, $files: [Upload]) {
    sendMessage(chatId: $chatId, text: $text, files: $files) {
      userId
      id
      chatId
      readAt
      createdAt
      readAt
      text
      files {
        id
        messageId
        path
      }
    }
  }
`;

export const READ_CHAT_MUTATION = gql`
  mutation readChat($chatId: ID!) {
    readChat(chatId: $chatId) {
      id
      type
      banned
      users {
        avatar
        firstname
        lastname
      }
      messages {
        text
        userId
        readAt
        createdAt
        files {
          path
        }
      }
      project {
        photos
      }
    }
  }
`;

export const ADMIN_DELETE_MUTATION = gql`
  mutation deleteAdmin($id: ID!) {
    deleteAdmin(id: $id)
  }
`;
