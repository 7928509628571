import { makeStyles } from '@mui/styles';

const useStyles = () =>
  makeStyles(() => ({
    spinnerWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
  }))();

export default useStyles;
