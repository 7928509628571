const pallete = {
  primary: '#1976d2',
  black: '#000000',
  loginBg: '#D3D2D2',
  white: '#ffffff',
  correct: '#177A00',
  error: '#BC0900',
  attention2: '#BD7800',
  info: '#0EA7C9',
  messageBg: '#2C2C2C',
  cardBorder: `1px solid #00000020`,
  cardShadow:
    '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
};

export default pallete;
