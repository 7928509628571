import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Avatar, Typography } from '@mui/material';
import { Box } from '@mui/system';
import classNames from 'classnames';
import { Field, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { useEffect } from 'react';
import { useHistory, withRouter } from 'react-router';

import CardTitleRow from '../../components/Card/CardTitleRow/CardTitleRow';
import MainLayout from '../../components/Layouts/MainLayout/MainLayout';
import Spinner from '../../components/Spinner/Spinner';
import { BASE_URL, ROUTES } from '../../config/constants';
import { setLocation } from '../../context/AuthContext';
import {
  PROJECT_CONFIRMED_MUTATION,
  PROJECT_DELETE_MUTATION,
  PROJECT_REJECT_MUTATION,
  UPDATE_PROJECT_MUTATION,
} from '../../graphql/mutations';
import { useProjectByIdQuery } from '../../hooks/useQueries';
import { projectSchemas } from '../../services/validation/schemas';
import fontSizes from '../../theme/fontSizes';
import fontWeights from '../../theme/fontWeights';
import { getDefaultDateFormat } from '../../utils/getDefaultDateFormat';
import useStyles from './styles';

const Project = ({ match }) => {
  const classes = useStyles();
  const projectId = match.params.id;
  const history = useHistory();
  const [projectQuery, { loading, data }] = useProjectByIdQuery();

  const [updateProjectMutation, { loading: updateProjectLoading }] =
    useMutation(UPDATE_PROJECT_MUTATION, {
      onCompleted: () => {
        getProject(projectId);
      },
    });

  const [confirmedProjectMutation, { loading: confirmedLoading }] = useMutation(
    PROJECT_CONFIRMED_MUTATION,
    {
      onCompleted: () => {
        getProject(projectId);
      },
    },
  );

  const [deleteProjectMutation, { loading: deleteLoading }] = useMutation(
    PROJECT_DELETE_MUTATION,
    {
      onCompleted: () => {
        getProject(projectId);
      },
    },
  );

  const [rejectProjectMutation, { loading: rejectLoading }] = useMutation(
    PROJECT_REJECT_MUTATION,
    {
      onCompleted: () => {
        getProject(projectId);
      },
    },
  );

  const project = data?.getAnyProjectByIdAdmin;

  const getProject = (id) => {
    projectQuery({
      variables: {
        id: +id,
      },
    });
  };

  const handleUpdate = (id, name, description) => {
    updateProjectMutation({
      variables: {
        data: {
          id: +id,
          name,
          description,
          type: project?.type,
        },
      },
    });
  };

  const handkeConfirmed = () => {
    confirmedProjectMutation({
      variables: {
        id: projectId,
      },
    });
  };

  const handleRejected = () => {
    rejectProjectMutation({
      variables: {
        id: projectId,
      },
    });
  };

  const handleDelete = () => {
    deleteProjectMutation({
      variables: {
        projectId: projectId,
      },
    });
  };

  const onSubmit = (values) => {
    handleUpdate(projectId, values?.name, values?.description);
  };

  useEffect(() => {
    getProject(projectId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  return (
    <MainLayout title={'Информация о проекте'}>
      <Box className={classes.wrapper}>
        {loading ? (
          <Spinner />
        ) : (
          <Formik
            initialValues={{
              name: project?.updates?.name || project?.name || '',
              description:
                project?.updates?.description || project?.description || '',
            }}
            onSubmit={onSubmit}
            validationSchema={projectSchemas}
          >
            {({ handleSubmit }) => (
              <>
                {data?.getAnyProjectByIdAdmin === null ? (
                  <Box>Проект не существует</Box>
                ) : (
                  <>
                    <Box
                      className={classes.projectListLink}
                      onClick={() => {
                        setLocation(ROUTES.projects);
                        history.push(ROUTES.projects);
                      }}
                    >
                      Вернуться к списку проектов
                    </Box>
                    <Box className={classes.cardWrapper}>
                      <Box className={classes.btnWrapper}>
                        {!project?.verified || project.updates ? (
                          <LoadingButton
                            loading={updateProjectLoading}
                            variant='outlined'
                            size='large'
                            className={classes.btn}
                            onClick={handleSubmit}
                          >
                            {'Сохранить изминения'}
                          </LoadingButton>
                        ) : null}

                        <Box ml='auto'>
                          {project?.verified && project.updates ? (
                            <LoadingButton
                              loading={rejectLoading}
                              variant='outlined'
                              size='large'
                              className={classNames(
                                classes.btn,
                                classes.verifiedBtn,
                              )}
                              onClick={handleRejected}
                            >
                              {'Отклонить модерацию'}
                            </LoadingButton>
                          ) : null}

                          {!project?.verified ? (
                            <LoadingButton
                              loading={confirmedLoading}
                              variant='outlined'
                              size='large'
                              className={classNames(
                                classes.btn,
                                classes.verifiedBtn,
                              )}
                              onClick={handkeConfirmed}
                            >
                              {'Подтвердить проект'}
                            </LoadingButton>
                          ) : null}

                          <LoadingButton
                            loading={deleteLoading}
                            variant='contained'
                            size='large'
                            className={classes.btn}
                            onClick={handleDelete}
                          >
                            {'Удалить проект'}
                          </LoadingButton>
                        </Box>
                      </Box>

                      {!project?.verified || project.updates ? (
                        <CardTitleRow
                          type='other'
                          label='Название:'
                          align='center'
                        >
                          <Field
                            component={TextField}
                            label={'Название'}
                            type='text'
                            name={'name'}
                            className={classes.input}
                          />
                        </CardTitleRow>
                      ) : (
                        <CardTitleRow
                          label='Название:'
                          content={project?.name}
                        />
                      )}

                      {!project?.verified || project.updates ? (
                        <CardTitleRow
                          type='other'
                          label='Описание:'
                          align='center'
                        >
                          <Field
                            component={TextField}
                            label={'Описание'}
                            multiline
                            rows={4}
                            name={'description'}
                            className={classes.input}
                          />
                        </CardTitleRow>
                      ) : (
                        <CardTitleRow
                          label='Описание:'
                          content={project?.description}
                        />
                      )}
                      <CardTitleRow
                        label='Тип:'
                        content={
                          project?.type === 'INVESTMENT'
                            ? 'Инвестиция'
                            : 'Продажа'
                        }
                      />
                      <CardTitleRow
                        label={`${
                          project?.type === 'INVESTMENT'
                            ? 'Общая сумма сбора'
                            : 'Стоимость'
                        }:`}
                        content={project?.updates?.cost || project?.cost}
                      />
                      {/* {project?.type === 'INVESTMENT' ? (
                        <CardTitleRow
                          label='Общая сумма собранных инвестиций:'
                          content={project?.totalInvestingSum}
                        />
                      ) : null} */}

                      {project?.minInvestingSum &&
                      project?.type === 'INVESTMENT' ? (
                        <CardTitleRow
                          label='Минимальная сумма для инвестицирования:'
                          content={
                            project?.updates?.minInvestingSum ||
                            project?.minInvestingSum
                          }
                        />
                      ) : null}

                      {project?.payout_frequence &&
                      project?.type === 'INVESTMENT' ? (
                        <CardTitleRow
                          label='Периодичность получения дивидендов:'
                          content={`Еже${
                            project?.payout_frequence === 'MONTHLY'
                              ? 'месячно'
                              : project?.payout_frequence === 'QUARTERLY'
                              ? 'квартально'
                              : 'годно'
                          }`}
                        />
                      ) : null}

                      <CardTitleRow
                        label='Просмотров:'
                        content={project?.viewers || 0}
                      />

                      {project?.organization ? (
                        <>
                          <CardTitleRow
                            label='Организация:'
                            content={
                              project?.organization?.type ===
                              'individualEntrepreneur'
                                ? 'Индивидуальный предприниматель'
                                : 'ООО'
                            }
                          />
                          {project?.organization?.FIO && (
                            <CardTitleRow
                              label={`ФИО ${
                                project?.organization?.type ===
                                'individualEntrepreneur'
                                  ? 'предпринимателя'
                                  : 'ген. директора'
                              }:`}
                              content={project?.organization?.FIO}
                            />
                          )}
                          {project?.organization?.companyName && (
                            <CardTitleRow
                              label={`Название компании:`}
                              content={project?.organization?.companyName}
                            />
                          )}
                          <CardTitleRow
                            label={`${
                              project?.organization?.type ===
                              'individualEntrepreneur'
                                ? 'ОГРНИП'
                                : 'ОГРН'
                            }:`}
                            content={project?.organization?.OGRNIP}
                          />
                          {project?.organization?.phone && (
                            <CardTitleRow
                              label='Телефон:'
                              content={project?.organization?.phone}
                            />
                          )}
                          {project?.organization?.email && (
                            <CardTitleRow
                              label='Email:'
                              content={project?.organization?.email}
                            />
                          )}
                        </>
                      ) : null}

                      {project?.promotions && project?.promotions.length > 0 ? (
                        <Box
                          className={classNames(
                            classes.rowWrapper,
                            classes.rowList,
                          )}
                        >
                          <CardTitleRow
                            label='Продвижения:'
                            content={project?.promotions?.length}
                            isBorder={false}
                            mb={20}
                            p={0}
                          />

                          {project?.promotions?.map((item, index) => {
                            return (
                              <Box key={index} className={classes.listWrapper}>
                                <CardTitleRow
                                  variant='secondary'
                                  label='Вид продвижения'
                                  content={item?.type}
                                />

                                <CardTitleRow
                                  variant='secondary'
                                  label='Срок окончания'
                                  content={getDefaultDateFormat(
                                    item?.dateExpiry,
                                  )}
                                />
                              </Box>
                            );
                          })}
                        </Box>
                      ) : null}

                      {project?.url && project?.url.length > 0 ? (
                        <Box
                          className={classNames(
                            classes.rowWrapper,
                            classes.rowList,
                          )}
                        >
                          <CardTitleRow
                            label='Информационные веб-ресурсы:'
                            content={project?.url?.length}
                            isBorder={false}
                            mb={20}
                            p={0}
                          />

                          {project?.url?.map((item, index) => {
                            return (
                              <Box key={index} className={classes.listWrapper}>
                                <CardTitleRow
                                  variant='secondary'
                                  label={item?.name}
                                  content={item?.url}
                                  action={() => {
                                    window.open(item?.url, '_blank');
                                  }}
                                />
                              </Box>
                            );
                          })}
                        </Box>
                      ) : null}

                      {project?.photos && project?.photos.length > 0 ? (
                        <Box
                          className={classNames(
                            classes.rowWrapper,
                            classes.rowList,
                          )}
                        >
                          <CardTitleRow
                            label='Фото:'
                            content={project?.photos?.length}
                            isBorder={false}
                            mb={20}
                            p={0}
                          />

                          <Box className={classes.photosWrapper}>
                            {project?.photos?.map((item, index) => {
                              return (
                                <img
                                  src={`${BASE_URL}/${item}`}
                                  key={index}
                                  alt={item}
                                  className={classes.photo}
                                />
                              );
                            })}
                          </Box>
                        </Box>
                      ) : null}

                      <Box
                        className={classNames(
                          classes.rowWrapper,
                          classes.rowList,
                        )}
                      >
                        <CardTitleRow
                          label='Жалобы:'
                          content={project?.complaints?.length}
                          isBorder={false}
                          mb={20}
                          p={0}
                        />

                        {project?.complaints?.map((item, index) => {
                          return (
                            <Box key={index} className={classes.listWrapper}>
                              <CardTitleRow
                                variant='secondary'
                                label='Вид жалобы'
                                content={item?.reason}
                              />

                              {item?.description ? (
                                <CardTitleRow
                                  variant='secondary'
                                  label='Описание'
                                  content={item?.description}
                                />
                              ) : null}

                              <CardTitleRow
                                variant='secondary'
                                type='avatar'
                                label='Автор жалобы'
                              >
                                <Box
                                  display='flex'
                                  alignItems='center'
                                  sx={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    setLocation(
                                      `${ROUTES.users}/${item?.owner?.id}`,
                                    );
                                    history.push(
                                      `${ROUTES.users}/${item?.owner?.id}`,
                                    );
                                  }}
                                >
                                  <Avatar
                                    src={`${BASE_URL}/${item?.owner.avatar}`}
                                  />
                                  <Typography
                                    ml={'20px'}
                                    variant='h6'
                                    fontSize={fontSizes.sm}
                                    fontWeight={fontWeights.REGUlAR}
                                  >
                                    {`${item?.owner.firstname} ${item?.owner.lastname}`}
                                  </Typography>
                                </Box>
                              </CardTitleRow>
                            </Box>
                          );
                        })}
                      </Box>
                    </Box>
                  </>
                )}
              </>
            )}
          </Formik>
        )}
      </Box>
    </MainLayout>
  );
};

export default withRouter(Project);
