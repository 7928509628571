import { makeStyles } from '@mui/styles';

import pallete from '../../theme/pallete';

const useStyles = () =>
  makeStyles(() => ({
    wrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: pallete.loginBg,
    },
    formWrapper: {
      width: 400,
      padding: 30,
      borderRadius: 10,
      border: `1px solid ${pallete.black}`,
      backgroundColor: pallete.white,
      height: 300,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    inputWrapper: {
      marginBottom: 20,
    },
    input: {
      width: '100%',
    },
    btnWrapper: {
      display: 'flex',
      justifyContent: 'center',
    },
    btn: {
      width: '50%',
    },
  }))();

export default useStyles;
