import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import MainLayout from '../../components/Layouts/MainLayout/MainLayout';
import TableList from '../../components/TableList/TableList';
import {
  PROJECTS_TABLE_HEAD_OPTIONS,
  PROJECTS_VERIFIED_SELECT_OPTIONS,
  ROUTES,
} from '../../config/constants';
import { setLocation } from '../../context/AuthContext';
import { useAllProjectsQuery } from '../../hooks/useQueries';
import { setFilterBy, setLimit, setPage } from '../../redux/reducers/projects';
import { projectSelector } from '../../redux/selectors';

function createData({ name, owner, verified, id, type, ...project }) {
  const ownerName = `${owner?.firstname} ${owner?.lastname}`;
  const complaintCount = project?.complaints?.length;
  const isEdit = !!project.updates;

  return { name, ownerName, verified, type, complaintCount, id, isEdit };
}

const Projects = () => {
  const [rows, setRows] = React.useState([]);
  const [projectsQuery, { loading }] = useAllProjectsQuery();

  const { projects, limit, page, count, filterBy } =
    useSelector(projectSelector);

  const dispatch = useDispatch();
  const history = useHistory();

  const handleChangePage = (event, newPage) => {
    dispatch(setPage({ page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setLimit({ limit: +event.target.value }));
    dispatch(setPage({ page: 0 }));
  };

  const handleFilterBy = (event) => {
    dispatch(setFilterBy({ filterBy: event.target.value }));
  };

  const handleRow = (id) => {
    setLocation(`${ROUTES.projects}/${id}`);
    history.push(`${ROUTES.projects}/${id}`);
  };

  const getFilterValue = (value) => {
    switch (value) {
      case 'verified':
        return {
          filterBy: 'CONFIRMED',
        };
      case 'no_verified':
        return {
          filterBy: 'UNCONFIRMED',
        };
      case 'on_moderation': {
        return {
          filterBy: 'EDITED',
        }
      }
      default:
        return {};
    }
  };

  const filterValue = getFilterValue(filterBy);

  React.useEffect(() => {
    if (projects.length > 0) {
      setRows(projects.map((project) => createData(project)));
    } else {
      setRows([]);
    }
  }, [projects]);

  const getProjects = () => {
    projectsQuery({
      variables: {
        limit: limit,
        offset: limit * page,
        ...filterValue,
      },
    });
  };

  React.useEffect(() => {
    getProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, page, filterBy]);

  return (
    <MainLayout title={'Список проектов'}>
      <Box display='flex' justifyContent='flex-end' mb={'20px'}>
        <FormControl sx={{ width: 300 }}>
          <InputLabel id='demo-simple-select-label'>
            Фильтрация по статусу
          </InputLabel>
          <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            value={filterBy}
            label='Фильтрация по статусу'
            onChange={handleFilterBy}
          >
            {PROJECTS_VERIFIED_SELECT_OPTIONS?.map((item, index) => {
              return (
                <MenuItem key={index} value={item.value}>
                  {item?.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
      <TableList
        isLoading={loading}
        hasFilter={true}
        count={count}
        limit={limit}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        headCells={PROJECTS_TABLE_HEAD_OPTIONS}
        rows={rows}
        type={'projects'}
        rowAction={handleRow}
      />
    </MainLayout>
  );
};

export default Projects;
