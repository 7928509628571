export const getLastMessageForChat = (messages) => {
  if (messages.length < 1) {
    return 'Сообщений нет';
  }

  if (messages[messages?.length - 1]?.text) {
    return messages[messages?.length - 1]?.text;
  }

  if (messages[messages?.length - 1]?.files) {
    return 'Файл';
  }
};

export const getLastMessageTimeForChat = (messages) => {
  if (messages.length < 1) {
    return 0;
  }

  if (messages[messages?.length - 1]?.createdAt) {
    return +messages[messages?.length - 1]?.createdAt;
  }
};
