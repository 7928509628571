import './App.css';
import 'react-toastify/dist/ReactToastify.css';

import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';

import TooltipContainer from './components/TooltipContainer/TooltipContainer';
import { AuthProvider } from './context/AuthContext';
import { ChatsContextProvider } from './context/ChatsContext';
import { NavigationContextProvider } from './context/NavigationContext';
import { RouterProvider } from './context/RouterContext';
import { client } from './graphql/client';
import { store } from './redux/store';
import theme from './theme/theme';

const App = () => {
  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <ChatsContextProvider>
              <NavigationContextProvider>
                <RouterProvider />
                <TooltipContainer />
              </NavigationContextProvider>
            </ChatsContextProvider>
          </AuthProvider>
        </ThemeProvider>
      </Provider>
    </ApolloProvider>
  );
};

export default App;
