import { makeStyles } from '@mui/styles';

import pallete from '../../theme/pallete';

const useStyles = () =>
  makeStyles(() => ({
    cardWrapper: {
      borderRadius: 4,
      paddingTop: 20,
      paddingRight: 20,
      boxShadow:
        '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    },
    rowWrapper: {
      padding: 20,
      borderBottom: `1px solid ${pallete.black}20`,
      display: 'flex',
    },
    rowList: {
      flexDirection: 'column',
    },
    listWrapper: {
      padding: '0 15px',
      marginBottom: 20,
      boxShadow:
        '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    },
    listRow: {
      padding: '15px 0',
      borderBottom: `1px solid ${pallete.black}20`,
      display: 'flex',
      alignItems: 'center',
      '&:last-child': {
        borderBottom: 'none',
      },
    },
    btnWrapper: {
      display: 'flex',
      marginLeft: 20,
      marginRight: 20,
    },
    btn: {
      width: 250,
    },
    verifiedBtn: {
      marginRight: '20px!important',
    },
    input: {
      width: 300,
    },
    projectListLink: {
      textDecoration: 'underline',
      marginBottom: 20,
      cursor: 'pointer',
    },
    photosWrapper: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    photo: {
      height: 300,
      width: 'auto',
      margin: 5,
      borderRadius: 10,
    },
  }))();

export default useStyles;
