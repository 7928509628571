import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';

import useStyles from './styles';

const Spinner = () => {
  const classes = useStyles();
  return (
    <Box className={classes.spinnerWrapper}>
      <CircularProgress />
    </Box>
  );
};

export default Spinner;
