import { useSubscription } from '@apollo/client';
import React, { useEffect } from 'react';

import { UNREAD_CHATS_COUNT_SUBSCRIPTION } from '../graphql/subscriptions';
import { useAllUnreadChatsQuery } from '../hooks/useQueries';
import { useAuthContext } from './AuthContext';

export const ChatsContext = React.createContext({});

export const useChatsContext = () => {
  const context = React.useContext(ChatsContext);
  if (!context) {
    throw new Error(
      'useChatsContext must be used within a ChatsContextProvider',
    );
  }
  return context;
};

export function ChatsContextProvider({ children }) {
  const [unreadChats, setUnreadChats] = React.useState(0);

  const [query, { data }] = useAllUnreadChatsQuery();
  const { user, isAuth } = useAuthContext();

  const { data: subscribedata } = useSubscription(
    UNREAD_CHATS_COUNT_SUBSCRIPTION,
    {
      variables: { userId: user?.id },
    },
  );

  useEffect(() => {
    isAuth && query();
  }, [isAuth, query]);

  useEffect(() => {
    data && setUnreadChats(data.getUnreadChatsCount);
  }, [data]);

  useEffect(() => {
    subscribedata && setUnreadChats(subscribedata?.unreadChatCount?.count);
  }, [subscribedata]);

  const handleUnreadChats = (data) => {
    setUnreadChats(data);
  };

  const value = {
    unreadChats,
    setUnreadChats: handleUnreadChats,
  };

  return (
    <ChatsContext.Provider value={value}>{children}</ChatsContext.Provider>
  );
}
