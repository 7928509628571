import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import MainLayout from '../../components/Layouts/MainLayout/MainLayout';
import TableList from '../../components/TableList/TableList';
import { ROUTES, USERS_TABLE_HEAD_OPTIONS } from '../../config/constants';
import { setLocation, setUserVariant } from '../../context/AuthContext';
import { useAllUsersQuery } from '../../hooks/useQueries';
import { setLimit, setPage } from '../../redux/reducers/users';
import { userSelector } from '../../redux/selectors';

function createData({ avatar, status, id, ...user }) {
  const name = `${user?.firstname} ${user?.lastname}`;
  const projectCount = user?.projects?.length;
  const complaintCount = user?.complaints?.length;
  return { avatar, name, projectCount, complaintCount, status, id };
}

const Users = () => {
  const [rows, setRows] = React.useState([]);
  const [usersQuery, { loading }] = useAllUsersQuery('users');

  const { users, limit, page, count } = useSelector(userSelector);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleChangePage = (event, newPage) => {
    dispatch(setPage({ page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setLimit({ limit: +event.target.value }));
    dispatch(setPage({ page: 0 }));
  };

  const handleRow = (id) => {
    setLocation(`${ROUTES.users}/${id}`);
    setUserVariant('user');
    history.push(`${ROUTES.users}/${id}`);
  };

  React.useEffect(() => {
    if (users.length > 0) {
      setRows(users.map((user) => createData(user)));
    } else {
      setRows([]);
    }
  }, [users]);

  const getUsers = () => {
    usersQuery({
      variables: {
        limit: limit,
        offset: limit * page,
        getOnly: 'ACTIVE',
      },
    });
  };

  React.useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, page]);

  return (
    <MainLayout title={'Список пользователей'}>
      <TableList
        isLoading={loading}
        count={count}
        limit={limit}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        headCells={USERS_TABLE_HEAD_OPTIONS}
        rows={rows}
        type={'users'}
        rowAction={handleRow}
      />
    </MainLayout>
  );
};

export default Users;
