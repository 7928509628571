import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import MainLayout from '../../components/Layouts/MainLayout/MainLayout';
import TableList from '../../components/TableList/TableList';
import { UNPICKED_CHATS_TABLE_HEAD_OPTIONS } from '../../config/constants';
import { CONNECT_TO_CHAT_QUERY } from '../../graphql/queries';
import { useAllUnpickedChatsQuery } from '../../hooks/useQueries';
import { setLimit, setPage } from '../../redux/reducers/unpickedChats';
import { unpickedChatsSelector } from '../../redux/selectors';
import { getLastMessageForChat } from '../../utils/getLastMessageForChat';

function createData({ id, users, project, messages }) {
  const userName1 = users[0]?.firstname
    ? `${users[0]?.firstname || ''} ${users[0]?.lastname || ''}`
    : '';
  const userName2 = users[1]?.firstname
    ? `${users[1]?.firstname || ''} ${users[1]?.lastname || ''}`
    : '';

  const projectName = project?.name;
  const action = id;
  const lastMessage = getLastMessageForChat(messages);
  return { userName1, userName2, projectName, action, lastMessage };
}

const UnpickedChats = () => {
  const [rows, setRows] = useState([]);
  const [chatsQuery, { loading }] = useAllUnpickedChatsQuery();

  const [connetToChatQuery, { loading: connectToChatLoading }] = useLazyQuery(
    CONNECT_TO_CHAT_QUERY,
    {
      onCompleted: () => {
        getChats();
      },
    },
  );

  const handleConnectToChat = (id) => {
    connetToChatQuery({
      variables: {
        id: +id,
      },
    });
  };

  const { chats, limit, page, count } = useSelector(unpickedChatsSelector);
  const dispatch = useDispatch();

  const handleChangePage = (event, newPage) => {
    dispatch(setPage({ page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setLimit({ limit: +event.target.value }));
    dispatch(setPage({ page: 0 }));
  };

  useEffect(() => {
    if (chats.length > 0) {
      setRows(chats.map((chat) => createData(chat)));
    } else {
      setRows([]);
    }
  }, [chats]);

  const getChats = () => {
    chatsQuery({
      variables: {
        limit: limit,
        offset: limit * page,
      },
    });
  };

  useEffect(() => {
    getChats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, page]);

  return (
    <MainLayout title={'Список не выбранных чатов'}>
      <TableList
        isLoading={loading}
        count={count}
        limit={limit}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        headCells={UNPICKED_CHATS_TABLE_HEAD_OPTIONS}
        rows={rows}
        type={'unpickedChats'}
        buttonAction={handleConnectToChat}
        buttonLoading={connectToChatLoading}
      />
    </MainLayout>
  );
};

export default UnpickedChats;
