import * as Yup from 'yup';

export const loginSchemas = Yup.object({
  password: Yup.string().required('Заполните данное поле'),
  login: Yup.string().required('Заполните данное поле'),
});

export const addAdminSchema = Yup.object({
  email: Yup.string().required('Заполните данное поле'),
  password: Yup.string().required('Заполните данное поле'),
  rePassword: Yup.string()
    .test('passwords-match', 'Пароли должны совпадать', function(value){
      return this.parent.password === value
    }),
});

export const projectSchemas = Yup.object({
  name: Yup.string().required('Заполните данное поле'),
});
