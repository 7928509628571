import { LoadingButton } from '@mui/lab';
import { Avatar, TableCell, TableRow } from '@mui/material';
import { Box } from '@mui/system';

import { BASE_URL } from '../../../config/constants';
import useStyles from './styles';

const TableListRow = ({
  row,
  rowAction,
  headCells,
  type,
  buttonAction,
  buttonLoading,
  actionButtonTitle,
}) => {
  const classes = useStyles();

  const getRowValue = (column, value) => {
    if (type === 'users' && column?.id === 'avatar') {
      return <Avatar src={`${BASE_URL}/${value}`} />;
    } else if (column?.id === 'status') {
      return (
        <Box display='flex' alignItems='center'>
          <Box width={150}>{!value ? 'Активный' : 'Заблокированный'}</Box>
        </Box>
      );
    } else if (type === 'projects' && column?.id === 'type') {
      if (value === 'INVESTMENT') {
        return 'Инвестиция';
      } else {
        return 'Продажа';
      }
    } else if (type === 'projects' && column?.id === 'verified') {
      if (row.isEdit) {
        return 'На модерации';
      } else if (value) {
        return 'Подтверджен';
      } else {
        return 'Не подтверджен';
      }
    } else if (buttonAction && column?.id === 'action') {
      return (
        <LoadingButton
          loading={buttonLoading}
          variant='outlined'
          size='large'
          onClick={() => buttonAction(value)}
        >
          {actionButtonTitle}
        </LoadingButton>
      );
    } else if (column?.id === 'countUnreadMessage') {
      if (value) {
        return (
          <Box component='span' className={classes.messagesCountLabel}>
            {value}
          </Box>
        );
      } else {
        return value;
      }
    } else {
      return value;
    }
  };

  return (
    <TableRow
      hover={rowAction ? true : false}
      role='checkbox'
      tabIndex={-1}
      key={row.code}
      sx={{ cursor: rowAction ? 'pointer' : 'auto' }}
      onClick={() => {
        rowAction && rowAction(row?.id);
      }}
    >
      {headCells.map((column) => {
        const value = row[column.id];
        return (
          <TableCell key={column.id} style={{ maxWidth: 200 }}>
            {/* {type === 'users' && column?.id === 'avatar' ? (
              <Avatar src={`${BASE_URL}/${value}`} />
            ) : column?.id === 'status' ? (
              <Box display='flex' alignItems='center'>
                <Box width={150}>{!value ? 'Активный' : 'Заблокированный'}</Box>
              </Box>
            ) : type === 'projects' && column?.id === 'type' ? (
              value === 'INVESTMENT' ? (
                'Инвестиция'
              ) : (
                'Продажа'
              )
            ) : type === 'projects' && column?.id === 'verified' ? (
              row.isEdit ? (
                'На модерации'
              ) : value ? (
                'Подтверджен'
              ) : (
                'Не подтверджен'
              )
            ) : buttonAction && column?.id === 'action' ? (
              <LoadingButton
                loading={buttonLoading}
                variant='outlined'
                size='large'
                onClick={() => buttonAction(value)}
              >
                {'Присоединиться'}
              </LoadingButton>
            ) : column.id === 'countUnreadMessage' ? (
              <Box className={classes.messagesCountLabel}>{value}</Box>
            ) : (
              value
            )} */}
            {getRowValue(column, value)}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default TableListRow;
