import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import MainLayout from '../../components/Layouts/MainLayout/MainLayout';
import TableList from '../../components/TableList/TableList';
import { ADMINS_TABLE_HEAD_OPTIONS } from '../../config/constants';
import { useAuthContext } from '../../context/AuthContext';
import { useDeleteAdminMutation } from '../../hooks/useMutations';
import { useAllAdminsQuery } from '../../hooks/useQueries';
import { setLimit, setPage } from '../../redux/reducers/admins';
import { adminsSelector } from '../../redux/selectors';

function createData({ avatar, login, id, ...user }) {
  const name = `${user?.firstname} ${user?.lastname}`;
  const action = id;

  return { avatar, name, id, login, action };
}

const Admins = () => {
  const [rows, setRows] = useState([]);

  const [usersQuery, { loading }] = useAllAdminsQuery();

  const [deleteUser, { loading: deleteAdminLoading }] = useDeleteAdminMutation({
    onCompleted: () => {
      getUsers();
    },
  });

  const { user } = useAuthContext();

  const handleDeleteAdmin = (id) => {
    deleteUser({
      variables: {
        id: +id,
      },
    });
  };

  const isUserSuperAdmin = user?.root;

  const { users, limit, page, count } = useSelector(adminsSelector);
  const dispatch = useDispatch();

  const handleChangePage = (event, newPage) => {
    dispatch(setPage({ page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setLimit({ limit: +event.target.value }));
    dispatch(setPage({ page: 0 }));
  };

  useEffect(() => {
    if (users.length > 0) {
      setRows(users.map((user) => createData(user)));
    } else {
      setRows([]);
    }
  }, [users]);

  const getUsers = () => {
    usersQuery({
      variables: {
        limit: limit,
        offset: limit * page,
      },
    });
  };
  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, page]);

  return (
    <MainLayout title={'Список администраторов'}>
      {!isUserSuperAdmin ? (
        'Вам не доступна данная страница'
      ) : (
        <TableList
          isLoading={loading}
          count={count}
          limit={limit}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          headCells={ADMINS_TABLE_HEAD_OPTIONS}
          rows={rows}
          type='users'
          buttonAction={handleDeleteAdmin}
          buttonLoading={deleteAdminLoading}
          actionButtonTitle={'Удалить'}
        />
      )}
    </MainLayout>
  );
};

export default Admins;
