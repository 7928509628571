import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import MainLayout from '../../components/Layouts/MainLayout/MainLayout';
import TableList from '../../components/TableList/TableList';
import {
  COMPLAINTS_TABLE_HEAD_OPTIONS,
  COMPLAINTS_VERIFIED_SELECT_OPTIONS,
  ROUTES,
} from '../../config/constants';
import { setLocation } from '../../context/AuthContext';
import { useAllComplaintsQuery } from '../../hooks/useQueries';
import {
  setFilterBy,
  setLimit,
  setPage,
} from '../../redux/reducers/complaints';
import { complaintsSelector } from '../../redux/selectors';

function createData({ user, project, reason, id, description, owner }) {
  const type = user ? `Пользователь` : 'Проект';

  const typeValueName = user
    ? `${user?.firstname || ''} ${user?.lastname || ''}`
    : project?.name;

  const ownerName = `${owner?.firstname} ${owner?.lastname}`;

  const resaonValue = `${reason} ${description ? '(' + description + ')' : ''}`;

  return { type, typeValueName, id, resaonValue, ownerName };
}

const Complaints = () => {
  const [rows, setRows] = useState([]);
  const [complaintsQuery, { loading }] = useAllComplaintsQuery();

  const { complaints, limit, page, count, filterBy } =
    useSelector(complaintsSelector);

  const dispatch = useDispatch();
  const history = useHistory();

  const handleChangePage = (event, newPage) => {
    dispatch(setPage({ page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setLimit({ limit: +event.target.value }));
    dispatch(setPage({ page: 0 }));
  };

  const handleFilterBy = (event) => {
    dispatch(setFilterBy({ filterBy: event.target.value }));
  };

  const handleRow = (id) => {
    setLocation(`${ROUTES.complaints}/${id}`);
    history.push(`${ROUTES.complaints}/${id}`);
  };

  const getFilterValue = (value) => {
    switch (value) {
      case 'user':
        return {
          getOnly: 'ONLY_USERS',
        };
      case 'project':
        return {
          getOnly: 'ONLY_PROJECTS',
        };
      default:
        return {};
    }
  };

  const filterValue = getFilterValue(filterBy);

  useEffect(() => {
    if (complaints.length > 0) {
      setRows(complaints.map((project) => createData(project)));
    } else {
      setRows([]);
    }
  }, [complaints]);

  const getComplaints = () => {
    complaintsQuery({
      variables: {
        limit: limit,
        offset: limit * page,
        ...filterValue,
      },
    });
  };

  useEffect(() => {
    getComplaints();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, page, filterBy]);

  return (
    <MainLayout title={'Список жалоб'}>
      <Box display='flex' justifyContent='flex-end' mb={'20px'}>
        <FormControl sx={{ width: 300 }}>
          <InputLabel id='demo-simple-select-label'>
            Фильтрация по виду
          </InputLabel>
          <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            value={filterBy}
            label='Фильтрация по статусу'
            onChange={handleFilterBy}
          >
            {COMPLAINTS_VERIFIED_SELECT_OPTIONS?.map((item, index) => {
              return (
                <MenuItem key={index} value={item.value}>
                  {item?.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
      <TableList
        isLoading={loading}
        hasFilter={true}
        count={count}
        limit={limit}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        headCells={COMPLAINTS_TABLE_HEAD_OPTIONS}
        rows={rows}
        type={'complaints'}
        rowAction={handleRow}
      />
    </MainLayout>
  );
};

export default Complaints;
