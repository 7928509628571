import { gql } from '@apollo/client';

export const SIGN_IN_QUERY = gql`
  query signIn($data: SignInInput!) {
    signIn(data: $data) {
      token
      user {
        id
        login
        firstname
        lastname
        avatar
      }
    }
  }
`;

export const CURRENT_USER_QUERY = gql`
  query {
    currentUser {
      id
      login
      firstname
      lastname
      avatar
      root
    }
  }
`;

export const ALL_USERS_QUERY = gql`
  query getAllUsersAdmin($limit: Int, $offset: Int, $getOnly: getOnly) {
    getAllUsersAdmin(limit: $limit, offset: $offset, getOnly: $getOnly) {
      count
      users {
        id
        firstname
        lastname
        avatar
        complaints {
          reason
        }
        projects {
          name
        }
        status
      }
    }
  }
`;

export const ALL_ADMINS_QUERY = gql`
  query getAdmisList($limit: Int, $offset: Int) {
    getAdmisList(limit: $limit, offset: $offset) {
      count
      users {
        id
        firstname
        lastname
        avatar
        login
      }
    }
  }
`;

export const USERS_BY_ID_QUERY = gql`
  query getUserByIdAdmin($id: ID!) {
    getUserByIdAdmin(id: $id) {
      id
      firstname
      lastname
      login
      avatar
      complaints {
        reason
        description
        owner {
          firstname
          lastname
          avatar
          id
        }
      }
      projects {
        name
        id
        owner {
          firstname
          lastname
          avatar
          id
        }
      }
      status
    }
  }
`;

export const ALL_PROJECTS_QUERY = gql`
  query getProjectsAdmin($limit: Int, $offset: Int, $filterBy: filterBy) {
    getProjectsAdmin(limit: $limit, offset: $offset, filterBy: $filterBy) {
      count
      projects {
        id
        name
        owner {
          firstname
          lastname
          avatar
          id
        }
        type
        verified
        complaints {
          reason
        }
        updates {
          id
          type
          name
          description
          cost
          totalInvestingSum
          minInvestingSum
          devidends
          payout_frequence
          categoryId
        }
      }
    }
  }
`;

export const PROJECTS_BY_ID_QUERY = gql`
  query getAnyProjectByIdAdmin($id: ID!) {
    getAnyProjectByIdAdmin(id: $id) {
      id
      name
      owner {
        firstname
        lastname
        avatar
        id
      }
      type
      description
      url {
        name
        url
      }
      cost
      totalInvestingSum
      minInvestingSum
      devidends
      payout_frequence
      viewers
      verified
      photos
      complaints {
        reason
        description
        owner {
          firstname
          lastname
          avatar
          id
        }
      }
      promotions {
        type
        dateExpiry
      }
      organization {
        id
        type
        FIO
        OGRNIP
        companyName
        phone
        email
      }
      updates {
        name
        description
        type
        name
        description
        cost
        totalInvestingSum
        minInvestingSum
        devidends
        payout_frequence
        categoryId
      }
    }
  }
`;

export const ALL_UNPICKED_CHATS_QUERY = gql`
  query getAllSafeUnpickedChatsAdmin($limit: Int, $offset: Int) {
    getAllSafeUnpickedChatsAdmin(limit: $limit, offset: $offset) {
      count
      chats {
        id
        project {
          name
        }
        users {
          firstname
          lastname
          login
        }
        messages {
          text
          userId
          files {
            id
            messageId
            path
          }
        }
      }
    }
  }
`;

export const ALL_CHATS_QUERY = gql`
  query getChats($limit: Int, $offset: Int) {
    getChats(limit: $limit, offset: $offset) {
      count
      chats {
        id
        project {
          name
        }
        users {
          id
          firstname
          lastname
          login
        }
        messages {
          text
          userId
          readAt
          createdAt
          files {
            id
            messageId
            path
          }
        }
      }
    }
  }
`;

export const CONNECT_TO_CHAT_QUERY = gql`
  query connectToSafeChatAdmin($id: ID) {
    connectToSafeChatAdmin(id: $id)
  }
`;

export const GET_PROJECTS_METRICS = gql`
  query getProjectMetrics(
    $organizationTypes: OrganizationTypes
    $type: ProjectTypes
    $startDate: String
    $endDate: String
  ) {
    getProjectMetrics(
      organizationTypes: $organizationTypes
      type: $type
      startDate: $startDate
      endDate: $endDate
    )
  }
`;

export const GET_PROMOTION_METRICS = gql`
  query getPromotionMetrics(
    $type: PromotionTypes
    $days: Int
    $startDate: String
    $endDate: String
  ) {
    getPromotionMetrics(
      type: $type
      days: $days
      startDate: $startDate
      endDate: $endDate
    )
  }
`;

export const GET_USER_METRICS = gql`
  query getUserMetrics($startDate: String, $endDate: String) {
    getUserMetrics(startDate: $startDate, endDate: $endDate)
  }
`;

export const CHAT_BY_ID_QUERY = gql`
  query getChatById($id: ID!) {
    getChatById(id: $id) {
      id
      type
      part
      cost
      project {
        photos
        name
        id
        cost
        minInvestingSum
        verified
        expiredIn
        type
        owner {
          id
          firstname
          lastname
          avatar
        }
      }
      users {
        id
        firstname
        lastname
        login
      }
      messages {
        id
        text
        chatId
        readAt
        userId
        createdAt
        files {
          id
          messageId
          path
        }
      }
    }
  }
`;

export const ALL_COMPLAINTS_QUERY = gql`
  query getAllComplain($limit: Int, $offset: Int, $getOnly: filterComplaint) {
    getAllComplain(limit: $limit, offset: $offset, getOnly: $getOnly) {
      count
      complaints {
        id
        user {
          id
          firstname
          lastname
          avatar
        }
        owner {
          firstname
          lastname
          avatar
          id
        }
        project {
          name
        }
        reason
        description
      }
    }
  }
`;

export const COMPLAINT_BY_ID_QUERY = gql`
  query getComplaintById($complaitId: ID) {
    getComplaintById(complaitId: $complaitId) {
      id
      user {
        id
        firstname
        lastname
        avatar
      }
      owner {
        firstname
        lastname
        avatar
        id
      }
      project {
        id
        name
      }
      reason
      description
    }
  }
`;

export const ALL_UNREAD_CHATS_QUERY = gql`
  query {
    getUnreadChatsCount
  }
`;
