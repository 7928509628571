import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  complaints: [],
  limit: 5,
  page: 0,
  count: 0,
  filterBy: 'all',
};

export const usersSlice = createSlice({
  name: 'complaints',
  initialState,
  reducers: {
    setComplaints: (state, action) => {
      state.complaints = action.payload.complaints;
      state.count = action.payload.count;
    },
    setLimit: (state, action) => {
      state.limit = action.payload.limit;
    },
    setPage: (state, action) => {
      state.page = action.payload.page;
    },
    setFilterBy: (state, action) => {
      state.filterBy = action.payload.filterBy;
      state.limit = 5;
      state.page = 0;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setComplaints, setLimit, setPage, setFilterBy } =
  usersSlice.actions;

export default usersSlice.reducer;
