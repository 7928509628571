import { Avatar, Typography } from '@mui/material';
import { Box } from '@mui/system';

import { BASE_URL } from '../../../config/constants';
import fontSizes from '../../../theme/fontSizes';
import fontWeights from '../../../theme/fontWeights';
import useStyles from './styles';

const CardTitleRow = ({
  variant = 'main',
  type = 'text',
  children,
  lableWidth = '300px',
  label,
  content,
  contentWidth = 'auto',
  p = 20,
  isBorder = true,
  mb = 0,
  align = 'normal',
  action,
}) => {
  const classes = useStyles({ p, isBorder, mb, align, action });

  return (
    <>
      {variant === 'main' ? (
        <Box className={classes.rowWrapper} onClick={action && action}>
          <Typography variant='h6' width={lableWidth}>
            {label}
          </Typography>
          {type === 'text' || type === 'statusText' ? (
            <Typography
              variant='h6'
              fontWeight={fontWeights.REGUlAR}
              width={contentWidth}
              className={classes.content}
              // dangerouslySetInnerHTML={{
              //   __html: String(content)?.replace('\n', '<br>'),
              // }}
            >
              <pre>{content}</pre>
            </Typography>
          ) : type === 'avatar' ? (
            <>{content && <Avatar src={`${BASE_URL}/${content}`} />}</>
          ) : null}
          {type === 'other' || type === 'statusText' ? children : null}
        </Box>
      ) : variant === 'secondary' ? (
        <Box className={classes.listRow} onClick={action && action}>
          <Typography variant='h6' width={lableWidth} fontSize={fontSizes.sm}>
            {label}
          </Typography>
          {type === 'text' ? (
            <Typography
              variant='h6'
              fontSize={fontSizes.sm}
              fontWeight={fontWeights.REGUlAR}
            >
              {content}
            </Typography>
          ) : type === 'avatar' ? (
            <> {children} </>
          ) : null}
        </Box>
      ) : null}
    </>
  );
};

export default CardTitleRow;
