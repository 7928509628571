import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Field, Formik } from 'formik';
import { TextField } from 'formik-mui';
import React, { useState } from 'react';

import MainLayout from '../../components/Layouts/MainLayout/MainLayout';
import { useAddAdmin } from '../../hooks/useMutations';
import { addAdminSchema } from '../../services/validation/schemas';

const AddAdmin = () => {
  const initialValues = {};

  const [addAdminMutation, { loading }] = useAddAdmin();
  const [photo, setPhoto] = useState(null);
  const [photoError, setPhotoError] = useState(false);

  const onSubmit = (values) => {
    if (!photo) {
      setPhotoError(true);
      return;
    }

    addAdminMutation({
      variables: {
        email: values.email,
        password: values.password,
        confirmPassword: values.rePassword,
        firstname: values.firstName,
        lastname: values.lastName,
        file: photo,
      },
    }).catch((err) => {
      alert(err);
    });
  };

  return (
    <MainLayout title={'Добавить администратора'}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={addAdminSchema}
      >
        {({ handleSubmit }) => (
          <Box display={'flex'} flexDirection={'row'} alignItems={'flex-end'}>
            <Box
              display={'flex'}
              flexDirection={'column'}
              alignItems={'flex-start'}
            >
              <Box mb={2}>
                <Field
                  component={TextField}
                  label={'Почта'}
                  type='text'
                  name={'email'}
                  disabled={false}
                />
              </Box>
              <Box mb={2}>
                <Field
                  component={TextField}
                  label={'Пароль'}
                  type='password'
                  name={'password'}
                  disabled={false}
                />
              </Box>
              <Box mb={2}>
                <Field
                  component={TextField}
                  label={'Подтвердите пароль'}
                  type='password'
                  name={'rePassword'}
                  disabled={false}
                />
              </Box>
              <Box mb={2}>
                <Field
                  component={TextField}
                  label={'Имя'}
                  type='text'
                  name={'firstName'}
                  disabled={false}
                />
              </Box>
              <Box mb={2}>
                <Field
                  component={TextField}
                  label={'Фамилия'}
                  type='text'
                  name={'lastName'}
                  disabled={false}
                />
              </Box>
              <Typography
                fontSize={14}
                mb={1}
                color={photoError ? 'red' : undefined}
              >
                Выбор фото
              </Typography>
              <Field
                type='file'
                name={`file`}
                onChange={async (e) => {
                  let file = await e.target.files[0];
                  setPhoto(file);
                }}
              />
            </Box>

            <Box ml={4} display={'flex'} flexDirection={'column'}>
              <LoadingButton
                loading={loading}
                variant='contained'
                size='large'
                onClick={() => handleSubmit()}
              >
                {'Зарегестрировать'}
              </LoadingButton>
            </Box>
          </Box>
        )}
      </Formik>
    </MainLayout>
  );
};

export default AddAdmin;
