import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';

import MainLayout from '../../components/Layouts/MainLayout/MainLayout';
import Spinner from '../../components/Spinner/Spinner';
import {
  useGetProjectMetrics,
  useGetPromotionMetrics,
  useGetUserMetrics,
} from '../../hooks/useQueries';

const Metrics = () => {
  const [
    getProjectMetricsQuery,
    { loading: projectMetricsLoading, data: projectMetricsData },
  ] = useGetProjectMetrics();
  const [
    getPromotionMetricsQuery,
    { loading: promotionMetricsLoading, data: promotionMetricsData },
  ] = useGetPromotionMetrics();
  const [
    getUserMetricsQuery,
    { loading: userMetricsLoading, data: userMetricsData },
  ] = useGetUserMetrics();

  const [projectTimeValue, setProjectTimeValue] = useState('day');
  const [projectTypeValue, setProjectType] = useState('INVESTMENT');
  const [investTypeValue, setInvestType] = useState('ALL');

  const [promotionTimeValue, setPromotionTimeValue] = useState('day');
  const [promotionTypeValue, setPromotionType] = useState('x2');
  const [promotionDaysValue, setPromotionDaysValue] = useState('7');

  const [usersTimeValue, setUsersTimeValue] = useState('day');

  useEffect(() => {
    const projectInvestValue =
      projectTypeValue === 'INVESTMENT' && investTypeValue !== 'ALL'
        ? { organizationTypes: investTypeValue }
        : {};

    getProjectMetricsQuery({
      variables: {
        type: projectTypeValue,
        startDate: getDate(projectTimeValue).toISOString(),
        endDate: new Date().toISOString(),
        ...projectInvestValue,
      },
    });
  }, [
    projectTimeValue,
    projectTypeValue,
    getProjectMetricsQuery,
    investTypeValue,
  ]);

  useEffect(() => {
    getPromotionMetricsQuery({
      variables: {
        type: promotionTypeValue,
        days: +promotionDaysValue,
        startDate: getDate(promotionTimeValue).toISOString(),
        endDate: new Date().toISOString(),
      },
    });
  }, [
    promotionTimeValue,
    promotionTypeValue,
    getPromotionMetricsQuery,
    promotionDaysValue,
  ]);

  useEffect(() => {
    getUserMetricsQuery({
      variables: {
        startDate: getDate(usersTimeValue).toISOString(),
        endDate: new Date().toISOString(),
      },
    });
  }, [usersTimeValue, getUserMetricsQuery]);

  const handleChangeProjectTime = (value: string) => {
    setProjectTimeValue(value);
  };

  const handleChangeProjectType = (value: string) => {
    setProjectType(value);
  };

  const handleChangeInvestType = (value: string) => {
    setInvestType(value);
  };

  const handleChangePromotionTime = (value: string) => {
    setPromotionTimeValue(value);
  };

  const handleChangePromotionType = (value: string) => {
    setPromotionType(value);
  };

  const handleChangePromotionDays = (value: string) => {
    setPromotionDaysValue(value);
  };

  const getDate = (value: string) => {
    let number = 1;

    switch (value) {
      case 'day':
        number = 1;
        break;
      case 'week':
        number = 7;
        break;
      case 'month':
        number = 30;
        break;
      case 'year':
        number = 365;
        break;
      default:
        number = 3650;
        break;
    }
    return new Date(Date.now() - number * 24 * 60 * 60 * 1000);
  };

  const timeValues = [
    {
      value: 'day',
      text: 'День',
    },
    {
      value: 'week',
      text: 'Неделя',
    },
    {
      value: 'month',
      text: 'Месяц',
    },
    {
      value: 'year',
      text: 'Год',
    },
    {
      value: 'allTime',
      text: 'Все время',
    },
  ];

  const projectTypes = [
    {
      type: 'INVESTMENT',
      value: 'Инвестиция',
    },
    {
      type: 'SALE',
      value: 'Продажа',
    },
  ];

  const investTypes = [
    {
      type: 'ALL',
      value: 'Все',
    },
    {
      type: 'individualEntrepreneur',
      value: 'ИП',
    },
    {
      type: 'limitedLiabilityCompany',
      value: 'ООО',
    },
  ];

  const promotionTypes = ['x2', 'x5', 'x10'];
  const promotionDays = ['7', '21', '45', '90'];

  return (
    <MainLayout title={'Статистика'}>
      <Box mb={4}>
        <Typography>Проекты</Typography>
        <Box
          mt={'20px'}
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
        >
          <FormControl sx={{ width: 150 }}>
            <InputLabel id='demo-simple-select-label'>Выбор даты</InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={projectTimeValue}
              label='Выбор даты'
              onChange={(e) => handleChangeProjectTime(e.target.value)}
            >
              {timeValues.map((el, index) => (
                <MenuItem key={index} value={el.value}>
                  {el.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box ml={2}>
            <FormControl sx={{ width: 150 }}>
              <InputLabel id='demo-simple-select-label'>Тип проекта</InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={projectTypeValue}
                label='Тип проекта'
                onChange={(e) => handleChangeProjectType(e.target.value)}
              >
                {projectTypes.map((el, index) => (
                  <MenuItem key={index} value={el.type}>
                    {el.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          {projectTypeValue === 'INVESTMENT' && (
            <Box ml={2}>
              <FormControl sx={{ width: 150 }}>
                <InputLabel id='demo-simple-select-label'>
                  Вид инвестиции
                </InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={investTypeValue}
                  label='Вид инвестиции'
                  onChange={(e) => handleChangeInvestType(e.target.value)}
                >
                  {investTypes.map((el, index) => (
                    <MenuItem key={index} value={el.type}>
                      {el.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}

          <Typography ml={2}>
            Количество: {projectMetricsData?.getProjectMetrics}
          </Typography>
          {projectMetricsLoading ? (
            <Box ml={2}>
              <Spinner />
            </Box>
          ) : null}
        </Box>
      </Box>
      <Box mb={4}>
        <Typography>Реклама</Typography>
        <Box
          mt={'20px'}
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
        >
          <FormControl sx={{ width: 150 }}>
            <InputLabel id='demo-simple-select-label'>Выбор даты</InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={promotionTimeValue}
              label='Выбор даты'
              onChange={(e) => handleChangePromotionTime(e.target.value)}
            >
              {timeValues.map((el, index) => (
                <MenuItem key={index} value={el.value}>
                  {el.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box ml={2}>
            <FormControl sx={{ width: 150 }}>
              <InputLabel id='demo-simple-select-label'>Тип проекта</InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={promotionTypeValue}
                label='Тип проекта'
                onChange={(e) => handleChangePromotionType(e.target.value)}
              >
                {promotionTypes.map((el, index) => (
                  <MenuItem key={index} value={el}>
                    {el}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box ml={2}>
            <FormControl sx={{ width: 150 }}>
              <InputLabel id='demo-simple-select-label'>
                Количество дней
              </InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={promotionDaysValue}
                label='Количество дней'
                onChange={(e) => handleChangePromotionDays(e.target.value)}
              >
                {promotionDays.map((el, index) => (
                  <MenuItem key={index} value={el}>
                    {el}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Typography ml={2}>
            Количество: {promotionMetricsData?.getPromotionMetrics}
          </Typography>
          {promotionMetricsLoading ? (
            <Box ml={2}>
              <Spinner />
            </Box>
          ) : null}
        </Box>
      </Box>
      <Box>
        <Typography>Пользователи</Typography>
        <Box
          mt={'20px'}
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
        >
          <FormControl sx={{ width: 150 }}>
            <InputLabel id='demo-simple-select-label'>Выбор даты</InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={usersTimeValue}
              label='Выбор даты'
              onChange={(e) => setUsersTimeValue(e.target.value)}
            >
              {timeValues.map((el, index) => (
                <MenuItem key={index} value={el.value}>
                  {el.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box />
          <Typography ml={2}>
            Количество: {userMetricsData?.getUserMetrics}
          </Typography>
          {userMetricsLoading ? (
            <Box ml={2}>
              <Spinner />
            </Box>
          ) : null}
        </Box>
      </Box>
    </MainLayout>
  );
};

export default Metrics;
