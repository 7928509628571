import { useLazyQuery } from '@apollo/client';
import { CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { toast } from 'react-toastify';

// import { client } from '../graphql/client';
import { CURRENT_USER_QUERY } from '../graphql/queries';

export function useAuthContext() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useUserContext must be used within a UserProvider');
  }
  return context;
}

//token
export const getToken = () => localStorage.getItem('token');
export const setToken = (token) => localStorage.setItem('token', token);
export const removeToken = () => localStorage.removeItem('token');

//location
export const getLocation = () => localStorage.getItem('location');
export const setLocation = (location) =>
  localStorage.setItem('location', location);
export const removeLocation = () => localStorage.removeItem('location');

//user variant
export const getUserVariant = () => localStorage.getItem('user-variant');
export const setUserVariant = (userVatiant) =>
  localStorage.setItem('user-variant', userVatiant);

const initialState = {
  user: null,
  isAuth: false,
  hasToken: getToken(),
  loading: false,
};

const AuthContext = createContext(initialState);

const reducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      setToken(action.token);
      return {
        ...state,
        user: action.user,
        isAuth: true,
        hasToken: true,
        loading: false,
      };

    case 'LOGOUT':
      removeToken();
      removeLocation();
      return {
        ...state,
        user: null,
        isAuth: false,
        hasToken: false,
        loading: false,
      };
    case 'SET_USER':
      if (action.payload) {
        return {
          ...state,
          user: action.payload,
          isAuth: true,
          hasToken: true,
          loading: false,
        };
      }
      return state;
    case 'START_LOADING':
      return {
        ...state,
        loading: true,
      };
    case 'FINISH_LOADING':
      return {
        ...state,
        loading: false,
      };

    default:
      throw new Error();
  }
};

const AuthProvider = ({ children }) => {
  const [userInfo, dispatch] = useReducer(reducer, initialState);
  const classes = useStyles();

  const [getCurrentUser, { loading }] = useLazyQuery(CURRENT_USER_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      if (!response.currentUser) {
        handleLogout();
      } else {
        setUser(response.currentUser);
      }
    },
    onError: () => {
      handleLogout();

      toast('Пользователь не существует', {
        type: 'error',
      });
    },
  });

  const handleLogin = (user, token) => {
    dispatch({ type: 'LOGIN', user, token });
  };
  const handleLogout = async () => {
    // await client.cache.reset();
    dispatch({ type: 'LOGOUT' });
  };

  const setUser = (user) => {
    dispatch({ type: 'SET_USER', payload: user });
  };
  const startLoading = () => {
    dispatch({ type: 'START_LOADING' });
  };
  const finishLoading = () => {
    dispatch({ type: 'FINISH_LOADING' });
  };

  const value = {
    isAuth: userInfo.isAuth,
    loading: userInfo.loading,
    user: userInfo?.user,
    hasToken: userInfo?.hasToken,
    handleLogout,
    handleLogin,
    setUser,
    startLoading,
    finishLoading,
  };

  useEffect(() => {
    if (value.hasToken) {
      startLoading();
      getCurrentUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.isAuth]);

  return (
    <AuthContext.Provider value={value}>
      {loading ? (
        <Box className={classes.spinnerWrapper}>
          <CircularProgress />
        </Box>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
};

const useStyles = () =>
  makeStyles(() => ({
    spinnerWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
    },
  }))();

export { AuthProvider };
