import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import { LoadingButton } from '@mui/lab';
import { IconButton } from '@mui/material';
import { Box } from '@mui/system';
import classNames from 'classnames';
import { Field, Formik } from 'formik';
import { TextField as TextFieldMUI } from 'formik-mui';
import { useCallback, useEffect, useRef } from 'react';
import { useHistory, withRouter } from 'react-router';

import CardTitleRow from '../../components/Card/CardTitleRow/CardTitleRow';
import MainLayout from '../../components/Layouts/MainLayout/MainLayout';
import Message from '../../components/Message/Message';
import Spinner from '../../components/Spinner/Spinner';
import { ROUTES } from '../../config/constants';
import { setLocation, useAuthContext } from '../../context/AuthContext';
import { useChat } from '../../hooks/useChat';
import {
  useDisconnectAdminFromChat,
  useReadChatMutation,
} from '../../hooks/useMutations';
import useStyles from './styles';

const Chat = ({ match }) => {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useAuthContext();
  const chatRef = useRef(null);

  const chatId = match.params.id;

  const { messages, initialMessages, loading, chatInfo, addMessage, addFile } =
    useChat(chatId, chatRef);

  const [disconectFromChatMutation, { loading: loadingDisconnect }] =
    useDisconnectAdminFromChat();
  const [readChatMutation] = useReadChatMutation({
    onError: (error) => {
      console.log(error);
    },
  });

  const readChat = useCallback(() => {
    readChatMutation({
      variables: {
        chatId: chatId,
      },
    });
  }, [chatId, readChatMutation]);

  const onSubmit = async (values, { resetForm }) => {
    addMessage(values?.text);
    resetForm();
  };

  useEffect(() => {
    if (chatRef?.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages, initialMessages, chatRef?.current]);

  useEffect(() => {
    if (messages?.length > 0 || initialMessages?.length > 0) {
      readChat();
    }
  }, [chatId, readChat, messages?.length, initialMessages?.length]);

  const handleDisconectBtn = () => {
    disconectFromChatMutation({
      variables: {
        id: chatId,
      },
    }).then((data) => {
      if (data.data.disconnectAdminFromChat) {
        handleGoBack();
      }
    });
  };

  const handleGoBack = () => {
    setLocation(ROUTES.chats);
    history.push(ROUTES.chats);
  };

  return (
    <MainLayout title={'Страница чата'}>
      <Box className={classes.wrapper}>
        {loading ? (
          <Spinner />
        ) : (
          <>
            {' '}
            <Box className={classes.chatListLink} onClick={handleGoBack}>
              Вернуться к списку чатов
            </Box>
            <Box className={classes.cardWrapper}>
              <Box className={classes.chatContentWrapper}>
                <Box sx={{ flex: 1 }}>
                  <CardTitleRow
                    label='Название проекта:'
                    content={chatInfo?.project?.name}
                  />
                  <CardTitleRow
                    label='Тип проекта:'
                    content={
                      chatInfo?.project?.type === 'INVESTMENT'
                        ? `Инвестиции: ${chatInfo?.part}% за ${chatInfo?.cost} ₽`
                        : `Продажа: ${chatInfo?.cost} ₽`
                    }
                  />
                  <Box
                    className={classNames(classes.rowWrapper, classes.rowList)}
                  >
                    <CardTitleRow
                      label='Пользователей в чате:'
                      content={chatInfo?.users?.length}
                      isBorder={false}
                      mb={20}
                      p={0}
                    />

                    {chatInfo?.users?.map((item, index) => {
                      return (
                        <Box key={index} className={classes.listWrapper}>
                          <CardTitleRow
                            variant='secondary'
                            label={`${index + 1}.`}
                            lableWidth='20px'
                            content={
                              item?.firstname
                                ? `${item?.firstname || ''} ${
                                    item?.lastname || ''
                                  }`
                                : item?.login
                            }
                            action={() => {
                              setLocation(`${ROUTES.users}/${item?.id}`);
                              history.push(`${ROUTES.users}/${item?.id}`);
                            }}
                          />
                        </Box>
                      );
                    })}
                  </Box>
                  <Box sx={{ padding: 2.5 }}>
                    <LoadingButton
                      loading={loadingDisconnect}
                      variant='contained'
                      size='large'
                      color='warning'
                      className={classes.logoutBtn}
                      disabled={loadingDisconnect}
                      onClick={handleDisconectBtn}
                    >
                      {'Покинуть чат'}
                    </LoadingButton>
                  </Box>
                </Box>
                <Box className={classes.chatMainWrapper}>
                  <Box
                    className={classNames(classes.rowWrapper, classes.rowList)}
                  >
                    <CardTitleRow
                      label='Чат:'
                      content={''}
                      isBorder={false}
                      mb={20}
                      p={0}
                    />

                    <Formik
                      initialValues={{
                        text: '',
                        file: '',
                      }}
                      onSubmit={onSubmit}
                    >
                      {({ handleSubmit, values }) => (
                        <>
                          <Box className={classes.chatWrapper} ref={chatRef}>
                            {initialMessages?.map((message, index) => {
                              return (
                                <Message
                                  key={index}
                                  type={message?.files.length ? 'FILE' : 'TEXT'}
                                  you={message?.userId === user?.id}
                                  date={Number(message?.createdAt)}
                                  message={message}
                                  users={chatInfo?.users}
                                  userId={user?.id}
                                />
                              );
                            })}
                            {messages?.map((message, index) => {
                              return (
                                <Message
                                  key={index}
                                  type={message?.files.length ? 'FILE' : 'TEXT'}
                                  you={message?.userId === user?.id}
                                  date={Number(message?.createdAt)}
                                  message={message}
                                  users={chatInfo?.users}
                                  userId={user?.id}
                                />
                              );
                            })}
                          </Box>
                          <Box className={classes.inputWrapper}>
                            <label htmlFor='file'>
                              <IconButton
                                size={'large'}
                                color='primary'
                                aria-label='upload picture'
                                component='span'
                              >
                                <AttachFileIcon />
                              </IconButton>
                            </label>

                            <Field
                              type='file'
                              name={`file`}
                              id={`file`}
                              onChange={async (e) => {
                                let file = await e.target.files[0];
                                addFile(file);
                              }}
                              className={classes.fileInput}
                            />
                            <Field
                              component={TextFieldMUI}
                              type='text'
                              name={'text'}
                              className={classes.input}
                            />
                            <LoadingButton
                              variant='contained'
                              size='small'
                              className={classes.btn}
                              onClick={handleSubmit}
                              endIcon={<SendIcon />}
                              disabled={!values.text}
                            >
                              {''}
                            </LoadingButton>
                          </Box>
                        </>
                      )}
                    </Formik>
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </MainLayout>
  );
};

export default withRouter(Chat);
