import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import { Avatar } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled, useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useHistory } from 'react-router';

import { BASE_URL, navigations } from '../../../config/constants';
import { setLocation, useAuthContext } from '../../../context/AuthContext';
import { useChatsContext } from '../../../context/ChatsContext';
import { useStoreContext } from '../../../context/NavigationContext';
import fontSizes from '../../../theme/fontSizes';
import fontWeights from '../../../theme/fontWeights';
import pallete from '../../../theme/pallete';
import useStyles from './styles';

const MainLayout = ({ title, children }) => {
  const theme = useTheme();
  const classes = useStyles();
  const { open, setOpen } = useStoreContext();
  const { user, handleLogout } = useAuthContext();
  const history = useHistory();

  const { unreadChats } = useChatsContext();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const isUserSuperAdmin = user?.root;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position='fixed' open={open}>
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            edge='start'
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant='h6' noWrap component='div' mr={'auto'}>
            Панель управления
          </Typography>
          <Typography variant='h6' noWrap component='div' mr={'20px'}>
            {`${user?.firstname || ''} ${user?.lastname || ''}`}
          </Typography>
          <Avatar
            alt={`${user?.firstname} ${user?.lastname}`}
            src={`${BASE_URL}/${user?.avatar}`}
          />
          <Box ml={'20px'}>
            <IconButton
              aria-label='add to shopping cart'
              onClick={handleLogout}
            >
              <LogoutIcon sx={{ color: pallete.white }} />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer variant='permanent' open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {navigations.map(
            ({ title, route, Icon, chatsCount, isSuperAdminPage }, index) => {
              if (isSuperAdminPage && !isUserSuperAdmin) {
                return <></>;
              }
              return (
                <ListItem
                  onClick={() => {
                    setLocation(route);
                    history.push(route);
                  }}
                  button
                  key={index}
                  className={
                    history.location.pathname === route
                      ? classes.activeNavElem
                      : null
                  }
                >
                  <ListItemIcon sx={{ position: 'relative' }}>
                    <Icon />
                  </ListItemIcon>
                  <ListItemText
                    primary={title}
                    className={
                      history.location.pathname === route
                        ? classes.activeNavElemText
                        : null
                    }
                  />
                  {chatsCount && unreadChats && (
                    <Box className={classes.chatsCountLabel}>{unreadChats}</Box>
                  )}
                </ListItem>
              );
            },
          )}
        </List>
        <Divider />
      </Drawer>
      <Box component='main' sx={{ flexGrow: 1, p: 3, mt: 8 }}>
        <Typography
          variant='h2'
          fontSize={fontSizes.ml}
          fontWeight={fontWeights.BOLD}
          mb={'30px'}
        >
          {title}
        </Typography>
        {children}
      </Box>
    </Box>
  );
};

export default MainLayout;

const drawerWidth = 340;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));
