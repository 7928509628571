import { configureStore } from '@reduxjs/toolkit';

import adminsReducer from './reducers/admins.js';
import banedUsersReducer from './reducers/banedUsers.js';
import chatsReducer from './reducers/chats.js';
import complaintsReducer from './reducers/complaints.js';
import projectsReducer from './reducers/projects.js';
import unpickedChatsReducer from './reducers/unpickedChats.js';
import usersReducer from './reducers/users.js';

export const store = configureStore({
  reducer: {
    users: usersReducer,
    admins: adminsReducer,
    banedUsers: banedUsersReducer,
    projects: projectsReducer,
    unpickedChats: unpickedChatsReducer,
    chats: chatsReducer,
    complaints: complaintsReducer,
  },
});
