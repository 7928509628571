import React, { createContext, useContext } from 'react';

export const NavigationContext = createContext({});

export const useStoreContext = () => {
  const context = useContext(NavigationContext);
  if (!context) {
    throw new Error(
      'useNavigationContext must be used within a NAvigationContextProvider',
    );
  }
  return context;
};

export const NavigationContextProvider = ({ children }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <NavigationContext.Provider value={{ open, setOpen }}>
      {children}
    </NavigationContext.Provider>
  );
};
