import { useLazyQuery, useMutation, useSubscription } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';

import { useAuthContext } from '../context/AuthContext';
import { SEND_MESSAGE_MUTATION } from '../graphql/mutations';
import { CHAT_BY_ID_QUERY } from '../graphql/queries';
import { NEW_MESSAGE_IN_CHAT_SUBSCRIPTION } from '../graphql/subscriptions';
import { isImage } from '../utils/isImageMessage';

export const useChat = (chatId, ref) => {
  const { user } = useAuthContext();

  const [query, { data: chatData, loading }] = useLazyQuery(CHAT_BY_ID_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted: (response) => {
      setInitialMessages(response?.getChatById?.messages);
    },
    onError: () => {
      console.log('error');
    },
  });

  const chatInfo = chatData?.getChatById;

  const [messages, setMessages] = useState([]);
  const [initialMessages, setInitialMessages] = useState([]);
  const generatedIds = useRef([]);

  const [mutation, { loading: sendMessageLoading }] = useMutation(
    SEND_MESSAGE_MUTATION,
    {
      onError: () => {
        setMessages((prevState) =>
          prevState?.map((item) => {
            const isError = generatedIds.current?.includes(item?.generatId);
            if (isError) {
              return { ...item, status: 'error' };
            } else {
              return item;
            }
          }),
        );
      },
      update: (_, data, { variables }) => {
        const generateIdIndex = generatedIds.current?.indexOf(
          variables?.generatId,
        );
        generatedIds.current?.splice(generateIdIndex, 1);

        setMessages((prevState) =>
          prevState.map((item) => {
            if (item?.generatId === variables?.generatId) {
              return { ...item, status: 'completed' };
            } else {
              return item;
            }
          }),
        );
      },
    },
  );
  const { data } = useSubscription(NEW_MESSAGE_IN_CHAT_SUBSCRIPTION, {
    variables: { chatId: Number(chatId) },
  });

  useEffect(() => {
    query({
      variables: {
        id: +chatId,
      },
    });
  }, []);

  useEffect(() => {
    const messageUserId = data?.newMessageInChat?.message?.userId;

    if (messageUserId !== undefined && messageUserId !== user.id) {
      setMessages((prevState) => [
        ...prevState,
        { ...data?.newMessageInChat?.message, readAt: 1 },
      ]);
    }
  }, [data, user.id]);

  const addMessage = (message) => {
    const dateNow = String(Date.now());
    generatedIds.current.push(dateNow);

    setMessages((prevState) => [
      ...prevState,
      {
        text: message,
        userId: user?.id,
        status: 'sent',
        files: [],
        generatId: dateNow,
      },
    ]);

    if (ref?.current) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }

    mutation({
      variables: {
        chatId: +chatId,
        text: message,
        generatId: dateNow,
      },
    });
  };

  const addFile = async (file) => {
    const dateNow = String(Date.now());
    generatedIds.current.push(dateNow);

    setMessages((prevState) => [
      ...prevState,
      {
        text: '',
        userId: user?.id,
        status: 'sent',
        files: [{ path: URL.createObjectURL(file) }],
        generatId: dateNow,
        extension: isImage(file?.name) ? 'IMAGE' : 'FILE',
      },
    ]);

    mutation({
      variables: {
        chatId: +chatId,
        files: [file],
        text: '',
        generatId: dateNow,
      },
    });
  };

  return {
    messages,
    initialMessages,
    loading,
    chatInfo,
    sendMessageLoading,
    addMessage,
    addFile,
    setMessages,
  };
};
