import LoadingButton from '@mui/lab/LoadingButton';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Field, Formik } from 'formik';
import { TextField } from 'formik-mui';

import { useSignInQuery } from '../../hooks/useQueries';
import { loginSchemas } from '../../services/validation/schemas';
import fontSizes from '../../theme/fontSizes';
import fontWeights from '../../theme/fontWeights';
import useStyles from './styles';

const initialValues = {
  login: '',
  password: '',
};

const Login = () => {
  const classes = useStyles();

  const [singInQuery, { loading }] = useSignInQuery();

  const onSubmit = (values) => {
    singInQuery({
      variables: {
        data: {
          login: values.login,
          password: values.password,
          fcmToken: '',
        },
      },
    });
  };

  return (
    <Box className={classes.wrapper}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={loginSchemas}
      >
        {({ handleSubmit }) => (
          <Box className={classes.formWrapper}>
            <Typography
              align='center'
              component='h2'
              mb={'20px'}
              fontSize={fontSizes.ml}
              fontWeight={fontWeights.BOLD}
            >
              {' '}
              Авторизация
            </Typography>
            <Box>
              <Box className={classes.inputWrapper}>
                <Field
                  component={TextField}
                  label={'Логин'}
                  type='text'
                  name={'login'}
                  disabled={false}
                  className={classes.input}
                />
              </Box>
              <Box className={classes.inputWrapper}>
                <Field
                  component={TextField}
                  label={'Пароль'}
                  type='password'
                  name={'password'}
                  disabled={false}
                  className={classes.input}
                />
              </Box>
            </Box>

            <Box className={classes.btnWrapper}>
              <LoadingButton
                loading={loading}
                variant='contained'
                size='large'
                color='primary'
                className={classes.btn}
                onClick={handleSubmit}
              >
                {'Войти'}
              </LoadingButton>
            </Box>
          </Box>
        )}
      </Formik>
    </Box>
  );
};

export default Login;
