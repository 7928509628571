import { useLazyQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { useAuthContext } from '../context/AuthContext';
import {
  ALL_ADMINS_QUERY,
  ALL_CHATS_QUERY,
  ALL_COMPLAINTS_QUERY,
  ALL_PROJECTS_QUERY,
  ALL_UNPICKED_CHATS_QUERY,
  ALL_UNREAD_CHATS_QUERY,
  ALL_USERS_QUERY,
  // CHAT_BY_ID_QUERY,
  COMPLAINT_BY_ID_QUERY,
  GET_PROJECTS_METRICS,
  GET_PROMOTION_METRICS,
  GET_USER_METRICS,
  PROJECTS_BY_ID_QUERY,
  SIGN_IN_QUERY,
  USERS_BY_ID_QUERY,
} from '../graphql/queries';
import { setUsers as setAdmins } from '../redux/reducers/admins';
import { setUsers as setBanedUsers } from '../redux/reducers/banedUsers';
import { setChats as setMyChats } from '../redux/reducers/chats';
import { setComplaints } from '../redux/reducers/complaints';
import { setProjects } from '../redux/reducers/projects';
import { setChats } from '../redux/reducers/unpickedChats';
import { setUsers } from '../redux/reducers/users';
import { getErrorMesage } from '../utils/getErrorMesage';

export const useSignInQuery = () => {
  const { handleLogin } = useAuthContext();
  return useLazyQuery(SIGN_IN_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      if (response?.signIn) {
        handleLogin(response?.signIn?.user, response?.signIn?.token);
      }
    },
    onError: (error) => {
      toast(getErrorMesage(error.message), {
        type: 'error',
      });
    },
  });
};

export const useGetUserMetrics = () => {
  return useLazyQuery(GET_USER_METRICS);
};

export const useGetProjectMetrics = () => {
  return useLazyQuery(GET_PROJECTS_METRICS);
};

export const useGetPromotionMetrics = () => {
  return useLazyQuery(GET_PROMOTION_METRICS);
};

export const useAllUsersQuery = (state) => {
  const dispatch = useDispatch();
  return useLazyQuery(ALL_USERS_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      const { users, count } = response?.getAllUsersAdmin;

      if (response?.getAllUsersAdmin) {
        if (state === 'users') {
          dispatch(setUsers({ users, count }));
        } else {
          dispatch(setBanedUsers({ users, count }));
        }
      }
    },
    onError: (error) => {
      toast(getErrorMesage(error.message), {
        type: 'error',
      });
    },
  });
};

export const useAllAdminsQuery = () => {
  const dispatch = useDispatch();
  return useLazyQuery(ALL_ADMINS_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      const { users, count } = response?.getAdmisList;

      if (response?.getAdmisList) {
        dispatch(setAdmins({ users, count }));
      }
    },
    onError: (error) => {
      toast(getErrorMesage(error.message), {
        type: 'error',
      });
    },
  });
};

export const useUserByIdQuery = () => {
  return useLazyQuery(USERS_BY_ID_QUERY, {
    fetchPolicy: 'network-only',
    onError: (error) => {
      toast(getErrorMesage(error.message), {
        type: 'error',
      });
    },
  });
};

export const useAllProjectsQuery = () => {
  const dispatch = useDispatch();
  return useLazyQuery(ALL_PROJECTS_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      const { projects, count } = response?.getProjectsAdmin;

      if (response?.getProjectsAdmin) {
        dispatch(setProjects({ projects, count }));
      }
    },
    onError: (error) => {
      toast(getErrorMesage(error.message), {
        type: 'error',
      });
    },
  });
};

export const useProjectByIdQuery = () => {
  return useLazyQuery(PROJECTS_BY_ID_QUERY, {
    fetchPolicy: 'network-only',
    onError: (error) => {
      toast(getErrorMesage(error.message), {
        type: 'error',
      });
    },
  });
};

export const useAllUnpickedChatsQuery = () => {
  const dispatch = useDispatch();
  return useLazyQuery(ALL_UNPICKED_CHATS_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      const { chats, count } = response?.getAllSafeUnpickedChatsAdmin;

      if (response?.getAllSafeUnpickedChatsAdmin) {
        dispatch(setChats({ chats, count }));
      }
    },
    onError: (error) => {
      toast(getErrorMesage(error.message), {
        type: 'error',
      });
    },
  });
};

export const useAllChatsQuery = () => {
  const dispatch = useDispatch();
  return useLazyQuery(ALL_CHATS_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      const { chats, count } = response?.getChats;

      if (response?.getChats) {
        dispatch(setMyChats({ chats, count }));
      }
    },
    onError: (error) => {
      toast(getErrorMesage(error.message), {
        type: 'error',
      });
    },
  });
};

// export const useChatByIdQuery = () => {
//   return useLazyQuery(CHAT_BY_ID_QUERY, {
//     fetchPolicy: 'network-only',
//     onError: (error) => {
//       toast(getErrorMesage(error.message), {
//         type: 'error',
//       });
//     },
//   });
// };

export const useAllComplaintsQuery = () => {
  const dispatch = useDispatch();
  return useLazyQuery(ALL_COMPLAINTS_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      const { complaints, count } = response?.getAllComplain;

      if (response?.getAllComplain) {
        dispatch(setComplaints({ complaints, count }));
      }
    },
    onError: (error) => {
      toast(getErrorMesage(error.message), {
        type: 'error',
      });
    },
  });
};

export const useComplaintByIdQuery = () => {
  return useLazyQuery(COMPLAINT_BY_ID_QUERY, {
    fetchPolicy: 'network-only',
    onError: (error) => {
      toast(getErrorMesage(error.message), {
        type: 'error',
      });
    },
  });
};

export const useAllUnreadChatsQuery = () => {
  return useLazyQuery(ALL_UNREAD_CHATS_QUERY, {
    fetchPolicy: 'network-only',

    onError: (error) => {
      toast(getErrorMesage(error.message), {
        type: 'error',
      });
    },
  });
};
