import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircle from '@mui/icons-material/CheckCircle';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { IconButton } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

import { BASE_URL } from '../../config/constants';
import { getDefaultDateFormat } from '../../utils/getDefaultDateFormat';
import { isImage } from '../../utils/isImageMessage';
import useStyles from './styles';

const Message = React.memo(
  ({ you, date, message, type = 'TEXT', users, userId }) => {
    const classes = useStyles({ you });
    const userInfo = users?.filter((item) => item?.id === message?.userId)[0];

    const userName = userInfo?.firstname
      ? `${userInfo?.firstname || ''} ${userInfo?.lastname || ''}`
      : userInfo?.login;

    return (
      <>
        <Box className={classes.mainMessageWrapper}>
          <Box className={classes.messageContentWrapper}>
            {type === 'TEXT' ? (
              <Box
                className={classes.messageText}
                dangerouslySetInnerHTML={{
                  __html: message?.text?.replace('\n', '<br>'),
                }}
              ></Box>
            ) : isImage(message?.files[0]?.path) ||
              message?.extension === 'IMAGE' ? (
              <Box
                className={classes.messageImg}
                onClick={() =>
                  window.open(
                    `${message?.extension === 'IMAGE' ? '' : BASE_URL + '/'}${
                      message?.files[0]?.path
                    }`,
                  )
                }
              >
                <img
                  alt={message?.files[0]?.path}
                  src={`${
                    message?.extension === 'IMAGE' ? '' : BASE_URL + '/'
                  }${message?.files[0]?.path}`}
                />
              </Box>
            ) : (
              <Box
                className={classes.messageFile}
                onClick={() =>
                  window.open(
                    `${message?.extension === 'FILE' ? '' : BASE_URL + '/'}${
                      message?.files[0].path
                    }`,
                  )
                }
              >
                <IconButton
                  size={'large'}
                  aria-label='upload picture'
                  component='span'
                >
                  <InsertDriveFileIcon />
                </IconButton>
              </Box>
            )}
          </Box>
        </Box>
        {message?.status === 'sent' || message?.status === 'error' ? (
          <Box className={classes.statusIconWrapper}>
            {message?.status === 'sent' ? (
              <AccessTimeIcon class={classes.statusIcon} />
            ) : message?.status === 'error' ? (
              <ErrorOutlineIcon
                class={classes.statusIcon}
                style={{ fill: 'red' }}
              />
            ) : null}
          </Box>
        ) : (
          <Box className={classes.messageDesc}>
            {`${getDefaultDateFormat(
              new Date(date ? date : Date.now()),
              'HH:mm d.MM.y',
            )} - ${userName}`}
            {message?.readAt || userId != message?.userId ? (
              <CheckCircle class={classes.messageReadStatus} />
            ) : (
              <CheckCircleOutline class={classes.messageReadStatus} />
            )}
          </Box>
        )}
      </>
    );
  },
);

export default Message;
