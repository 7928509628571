import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Avatar, Typography } from '@mui/material';
import { Box } from '@mui/system';
import classNames from 'classnames';
import { useEffect } from 'react';
import { useHistory, withRouter } from 'react-router';

import CardTitleRow from '../../components/Card/CardTitleRow/CardTitleRow';
import MainLayout from '../../components/Layouts/MainLayout/MainLayout';
import Spinner from '../../components/Spinner/Spinner';
import { BASE_URL, ROUTES } from '../../config/constants';
import { getUserVariant, setLocation } from '../../context/AuthContext';
import { BLOCK_UNBLOCK_MUTATION } from '../../graphql/mutations';
import { useUserByIdQuery } from '../../hooks/useQueries';
import fontSizes from '../../theme/fontSizes';
import fontWeights from '../../theme/fontWeights';
import useStyles from './styles';

const User = ({ match }) => {
  const classes = useStyles();
  const userId = match.params.id;
  const history = useHistory();
  const [userQuery, { loading, data }] = useUserByIdQuery();

  const [blockUserMutation, { loading: userBlockLoading }] = useMutation(
    BLOCK_UNBLOCK_MUTATION,
    {
      onCompleted: () => {
        getUser(userId);
      },
    },
  );

  const user = data?.getUserByIdAdmin;
  const variantUser = getUserVariant();

  const getUser = (id) => {
    userQuery({
      variables: {
        id: +id,
      },
    });
  };

  const blockUnblockMutation = (id) => {
    blockUserMutation({
      variables: {
        id: +id,
      },
    });
  };

  const handleLocation = () => {
    if (variantUser === 'user') {
      setLocation(ROUTES.users);
      history.push(ROUTES.users);
    } else {
      setLocation(ROUTES.banedUsers);
      history.push(ROUTES.banedUsers);
    }
  };

  useEffect(() => {
    getUser(userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return (
    <MainLayout title={'Информация о пользователе'}>
      <Box className={classes.wrapper}>
        {loading ? (
          <Spinner />
        ) : (
          <>
            <Box className={classes.userListLink} onClick={handleLocation}>
              {`Вернуться к списку ${
                variantUser === 'user' ? 'активных' : 'заблокированных'
              }  пользователей`}
            </Box>
            <Box className={classes.cardWrapper}>
              <CardTitleRow
                label='Имя, Фамилия:'
                content={`${user?.firstname || ''} ${user?.lastname || ''}`}
              />
              <CardTitleRow
                label='Логин (email):'
                content={`${user?.login || 'Email отсутствует'}`}
              />
              <CardTitleRow
                type='avatar'
                label='Аватарка:'
                content={user?.avatar}
              />

              <CardTitleRow
                type='statusText'
                label='Статус:'
                content={!user?.status ? 'Активный' : 'Заблокированный'}
                contentWidth='200px'
              >
                <LoadingButton
                  variant={user?.status ? 'contained' : 'outlined'}
                  loading={userBlockLoading}
                  onClick={() => blockUnblockMutation(userId)}
                >
                  {!user?.status ? 'Заблокировать' : 'Разблокировать'}
                </LoadingButton>
              </CardTitleRow>

              <Box className={classNames(classes.rowWrapper, classes.rowList)}>
                <CardTitleRow
                  label='Жалобы:'
                  content={user?.complaints?.length}
                  isBorder={false}
                  mb={20}
                  p={0}
                />

                {user?.complaints?.map((item, index) => {
                  return (
                    <Box key={index} className={classes.listWrapper}>
                      <CardTitleRow
                        variant='secondary'
                        label='Вид жалобы'
                        content={item?.reason}
                      />

                      {item?.description ? (
                        <CardTitleRow
                          variant='secondary'
                          label='Описание'
                          content={item?.description}
                        />
                      ) : null}

                      <CardTitleRow
                        variant='secondary'
                        type='avatar'
                        label='Автор жалобы'
                      >
                        <Box
                          display='flex'
                          alignItems='center'
                          sx={{ cursor: 'pointer' }}
                          onClick={() => {
                            setLocation(`${ROUTES.users}/${item?.owner?.id}`);
                            history.push(`${ROUTES.users}/${item?.owner?.id}`);
                          }}
                        >
                          <Avatar src={`${BASE_URL}/${item?.owner.avatar}`} />
                          <Typography
                            ml={'20px'}
                            variant='h6'
                            fontSize={fontSizes.sm}
                            fontWeight={fontWeights.REGUlAR}
                          >
                            {`${item?.owner.firstname} ${item?.owner.lastname}`}
                          </Typography>
                        </Box>
                      </CardTitleRow>
                    </Box>
                  );
                })}
              </Box>

              <Box className={classNames(classes.rowWrapper, classes.rowList)}>
                <CardTitleRow
                  label='Проекты:'
                  content={user?.projects?.length}
                  isBorder={false}
                  mb={20}
                  p={0}
                />

                {user?.projects?.map((item, index) => {
                  return (
                    <Box key={index} className={classes.listWrapper}>
                      <CardTitleRow
                        variant='secondary'
                        label='Название проекта'
                        content={item?.name}
                        action={() => {
                          setLocation(`${ROUTES.projects}/${item?.id}`);
                          history.push(`${ROUTES.projects}/${item?.id}`);
                        }}
                      />
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </>
        )}
      </Box>
    </MainLayout>
  );
};

export default withRouter(User);
