import { makeStyles } from '@mui/styles';

import pallete from '../../theme/pallete';

const useStyles = () =>
  makeStyles(() => ({
    cardWrapper: {
      borderRadius: 4,
      boxShadow: pallete.cardShadow,
    },
    rowWrapper: {
      padding: 20,
      borderBottom: pallete.cardBorder,
      display: 'flex',
    },
    rowList: {
      flexDirection: 'column',
    },
    listWrapper: {
      padding: '0 15px',
      marginBottom: 20,
      boxShadow: pallete.cardShadow,
    },
    listRow: {
      padding: '15px 0',
      borderBottom: pallete.cardBorder,
      display: 'flex',
      alignItems: 'center',
      '&:last-child': {
        borderBottom: 'none',
      },
    },
    userListLink: {
      textDecoration: 'underline',
      marginBottom: 20,
      cursor: 'pointer',
    },
  }))();

export default useStyles;
