import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  chats: [],
  limit: 5,
  page: 0,
  count: 0,
};

export const unpickedChatsSlice = createSlice({
  name: 'unpickedChats',
  initialState,
  reducers: {
    setChats: (state, action) => {
      state.chats = action.payload.chats;
      state.count = action.payload.count;
    },
    setLimit: (state, action) => {
      state.limit = action.payload.limit;
    },
    setPage: (state, action) => {
      state.page = action.payload.page;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setChats, setLimit, setPage } = unpickedChatsSlice.actions;

export default unpickedChatsSlice.reducer;
