import { makeStyles } from '@mui/styles';

import pallete from '../../../theme/pallete';

const useStyles = ({ p, isBorder, mb, align, action }) =>
  makeStyles(() => ({
    rowWrapper: {
      padding: p,
      borderBottom: isBorder ? pallete.cardBorder : 'none',
      display: 'flex',
      marginBottom: mb,
      alignItems: align,
      cursor: action ? 'pointer' : 'auto',
    },
    listRow: {
      padding: '15px 0',
      borderBottom: pallete.cardBorder,
      display: 'flex',
      alignItems: 'center',
      '&:last-child': {
        borderBottom: 'none',
      },
      cursor: action ? 'pointer' : 'auto',
    },
    content: {
      '& pre': {
        margin: 0,
        fontFamily: 'Roboto',
      },
    },
  }))();

export default useStyles;
