import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  users: [],
  limit: 5,
  page: 0,
  count: 0,
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsers: (state, action) => {
      state.users = action.payload.users;
      state.count = action.payload.count;
    },
    setLimit: (state, action) => {
      state.limit = action.payload.limit;
    },
    setPage: (state, action) => {
      state.page = action.payload.page;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUsers, setLimit, setPage } = usersSlice.actions;

export default usersSlice.reducer;
