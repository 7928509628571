import { makeStyles } from '@mui/styles';

const useStyles = () =>
  makeStyles(() => ({
    messagesCountLabel: {
      fontSize: ' 12px',
      backgroundColor: 'red',
      borderRadius: '20px',
      top: 0,
      color: '#fff',
      left: '30px',
      textAlign: 'center',
      lineHeight: '20px',
      padding: '2px 5px',
    },
  }))();

export default useStyles;
