import { useSubscription } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import MainLayout from '../../components/Layouts/MainLayout/MainLayout';
import TableList from '../../components/TableList/TableList';
import { CHATS_TABLE_HEAD_OPTIONS, ROUTES } from '../../config/constants';
import { setLocation, useAuthContext } from '../../context/AuthContext';
import { NEW_MESSAGE_IN_ALL_CHATS_SUBSCRIPTION } from '../../graphql/subscriptions';
import { useAllChatsQuery } from '../../hooks/useQueries';
import { setChats, setLimit, setPage } from '../../redux/reducers/chats';
import { chatsSelector } from '../../redux/selectors';
import {
  getLastMessageForChat,
  getLastMessageTimeForChat,
} from '../../utils/getLastMessageForChat';

const Chats = () => {
  const [rows, setRows] = useState([]);
  const [chatsQuery, { loading }] = useAllChatsQuery();

  const { chats, limit, page, count } = useSelector(chatsSelector);
  const { user } = useAuthContext();
  const dispatch = useDispatch();
  const history = useHistory();

  const { data } = useSubscription(NEW_MESSAGE_IN_ALL_CHATS_SUBSCRIPTION, {
    variables: { userId: user.id },
  });

  useEffect(() => {
    if (data) {
      const message = {
        ...data?.newMessage.message,
        createdAt: Date.now(data?.newMessage.message.createdAt),
      };

      const chatId = message?.chatId;
      const chatIdIndex = chats?.findIndex((chat) => chat?.id === chatId);

      if (chatIdIndex >= 0) {
        const newChatsValue = JSON.parse(JSON.stringify(chats));
        newChatsValue[chatIdIndex].messages.push(message);

        dispatch(setChats({ chats: newChatsValue, count }));
      } else {
        getChats();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleChangePage = (event, newPage) => {
    dispatch(setPage({ page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setLimit({ limit: +event.target.value }));
    dispatch(setPage({ page: 0 }));
  };

  const handleRow = (id) => {
    setLocation(`${ROUTES.chats}/${id}`);
    history.push(`${ROUTES.chats}/${id}`);
  };

  useEffect(() => {
    if (chats.length > 0) {
      setRows(chats.map((chat) => createData(chat, user?.id)));
    } else {
      setRows([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chats]);

  const getChats = () => {
    chatsQuery({
      variables: {
        limit: limit,
        offset: limit * page,
      },
    });
  };

  const getSortChats = (rows) => {
    return (
      rows.sort(function (a, b) {
        return b.lastMessageTime - a.lastMessageTime;
      }) || []
    );
  };

  const rowsValue = getSortChats(rows);

  useEffect(() => {
    getChats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, page]);

  function createData({ id, users, project, messages }, userId) {
    const filterUser = users?.filter((item) => item?.id !== userId);

    const userName1 = filterUser[0]?.firstname
      ? `${filterUser[0]?.firstname || ''} ${filterUser[0]?.lastname || ''}`
      : '';

    const userName2 = filterUser[1]?.firstname
      ? `${filterUser[1]?.firstname || ''} ${filterUser[1]?.lastname || ''}`
      : '';

    const projectName = project?.name;
    const lastMessage = getLastMessageForChat(messages);
    const lastMessageTime = getLastMessageTimeForChat(messages);

    const countUnreadMessage =
      messages?.filter(
        (message) => !message?.readAt && user.id !== message?.userId,
      )?.length || 0;

    return {
      userName1,
      userName2,
      projectName,
      lastMessage,
      id,
      countUnreadMessage,
      lastMessageTime,
    };
  }

  return (
    <MainLayout title={'Список моих чатов'}>
      <TableList
        isLoading={loading}
        count={count}
        limit={limit}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        headCells={CHATS_TABLE_HEAD_OPTIONS}
        rows={rowsValue}
        type={'chats'}
        rowAction={handleRow}
      />
    </MainLayout>
  );
};

export default Chats;
