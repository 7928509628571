import { makeStyles } from '@material-ui/styles';

import pallete from '../../theme/pallete';

const useStyles = makeStyles(() => ({
  tooltipContainer: {
    width: '100%',
    bottom: 0,
    textAlign: 'center',
    '& > div': {
      marginBottom: 2,
      borderRadius: 0,
    },
    '& .Toastify__toast--success': {
      background: pallete.correct,
    },
    '& .Toastify__toast--error': {
      background: pallete.error,
      color: pallete.white,
    },
    '& .Toastify__toast--warning': {
      background: pallete.attention2,
    },
    '& .Toastify__toast--info': {
      background: pallete.info,
    },
  },
}));

export default useStyles;
