import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import { useHistory } from 'react-router';

import CardTitleRow from '../../components/Card/CardTitleRow/CardTitleRow';
import MainLayout from '../../components/Layouts/MainLayout/MainLayout';
import Spinner from '../../components/Spinner/Spinner';
import { ROUTES } from '../../config/constants';
import { setLocation } from '../../context/AuthContext';
import { COMPLAINT_DELETE_MUTATION } from '../../graphql/mutations';
import { useComplaintByIdQuery } from '../../hooks/useQueries';
import useStyles from './styles';

const Complaint = ({ match }) => {
  const classes = useStyles();
  const complaintId = match.params.id;
  const history = useHistory();
  const [сomplaintQuery, { loading, data }] = useComplaintByIdQuery();

  const сomplaint = data?.getComplaintById;

  const getComplaint = (id) => {
    сomplaintQuery({
      variables: {
        complaitId: +id,
      },
    });
  };

  const [deleteComplaintMutation, { loading: deleteLoading }] = useMutation(
    COMPLAINT_DELETE_MUTATION,
    {
      onCompleted: () => {
        getComplaint(complaintId);
      },
    },
  );

  const handleChange = (value) => {
    setLocation(value);
    history.push(value);
  };

  const handleDelete = () => {
    deleteComplaintMutation({
      variables: {
        id: complaintId,
      },
    });
  };

  useEffect(() => {
    getComplaint(complaintId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [complaintId]);

  return (
    <MainLayout title={'Информация о жалобе'}>
      <Box className={classes.wrapper}>
        {loading ? (
          <Spinner />
        ) : (
          <>
            {сomplaint === null ? (
              <Box>Жалобы не существует</Box>
            ) : (
              <>
                <Box
                  className={classes.userListLink}
                  onClick={() => {
                    setLocation(ROUTES.complaints);
                    history.push(ROUTES.complaints);
                  }}
                >
                  Вернуться к списку жалоб
                </Box>
                <Box className={classes.cardWrapper}>
                  <Box className={classes.btnWrapper}>
                    <Box ml='auto'>
                      <LoadingButton
                        loading={deleteLoading}
                        variant='contained'
                        size='large'
                        className={classes.btn}
                        onClick={handleDelete}
                      >
                        {'Удалить жалобу'}
                      </LoadingButton>
                    </Box>
                  </Box>

                  <CardTitleRow
                    label='Автор жалобы:'
                    content={`${сomplaint?.owner?.firstname} ${сomplaint?.owner?.lastname}`}
                    action={() => {
                      handleChange(`${ROUTES.users}/${сomplaint?.owner?.id}`);
                    }}
                  />
                  <CardTitleRow
                    label='Вид жалобы:'
                    content={сomplaint?.user ? `Пользователь` : 'Проект'}
                  />
                  <CardTitleRow
                    label='На кого жалоба:'
                    content={
                      сomplaint?.user
                        ? `${сomplaint?.user?.firstname || ''} ${
                            сomplaint?.user?.lastname || ''
                          }`
                        : сomplaint?.project?.name
                    }
                    action={() =>
                      handleChange(
                        сomplaint?.user
                          ? `${ROUTES.users}/${сomplaint?.user?.id}`
                          : `${ROUTES.projects}/${сomplaint?.project?.id}`,
                      )
                    }
                  />
                  <CardTitleRow
                    label='Суть жалобы:'
                    content={`${сomplaint?.reason} ${
                      сomplaint?.description
                        ? '(' + сomplaint?.description + ')'
                        : ''
                    }`}
                  />
                </Box>
              </>
            )}
          </>
        )}
      </Box>
    </MainLayout>
  );
};

export default Complaint;
