import { makeStyles } from '@mui/styles';

import pallete from '../../../theme/pallete';

const useStyles = () =>
  makeStyles(() => ({
    activeNavElem: {
      backgroundColor: `${pallete.primary} !important`,
      '& svg': {
        color: pallete.white,
      },
      '&:hover': {
        backgroundColor: `${pallete.primary} !important`,
        color: pallete.white,
      },
    },
    activeNavElemText: {
      color: pallete.white,
    },
    chatsCountLabel: {
      position: 'absolute',
      fontSize: ' 12px',
      backgroundColor: 'red',
      borderRadius: '20px',
      top: 0,
      color: '#fff',
      left: '30px',
      textAlign: 'center',
      lineHeight: '20px',
      padding: '2px 5px',
    },
  }))();

export default useStyles;
