import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
} from '@mui/material';

import { TABLE_LIMITS_ARRAY_OPTIONS } from '../../config/constants';
import Spinner from '../Spinner/Spinner';
import TableListHeader from './TableListHeader/TableListHeader';
import TableListRow from './TableListRow/TableListRow';

const TableList = ({
  isLoading,
  count,
  limit,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  headCells,
  rows,
  rowAction,
  type,
  hasFilter = false,
  buttonAction,
  buttonLoading,
  actionButtonTitle = 'Присоедениться',
}) => {
  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer
        sx={{ height: `calc(100vh - 250px - ${hasFilter ? '76px' : '0px'})` }}
      >
        {isLoading ? (
          <Spinner />
        ) : (
          <Table stickyHeader aria-label='sticky table'>
            <TableListHeader headCells={headCells} />
            <TableBody>
              {rows?.map((row, index) => {
                return (
                  <TableListRow
                    key={index}
                    row={row}
                    rowAction={rowAction}
                    headCells={headCells}
                    type={type}
                    buttonAction={buttonAction}
                    buttonLoading={buttonLoading}
                    actionButtonTitle={actionButtonTitle}
                  />
                );
              })}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={TABLE_LIMITS_ARRAY_OPTIONS}
        component='div'
        count={count}
        rowsPerPage={limit}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default TableList;
