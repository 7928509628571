import { useMutation } from '@apollo/client';

import {
  ADD_ADMIN,
  ADMIN_DELETE_MUTATION,
  DISCONECT_ADMIN_FROM_CHAT,
  READ_CHAT_MUTATION,
  SEND_MESSAGE_MUTATION,
} from '../graphql/mutations';

export const useSenMessageMutation = () => {
  return useMutation(SEND_MESSAGE_MUTATION);
};

export const useAddAdmin = () => {
  return useMutation(ADD_ADMIN);
};

export const useDisconnectAdminFromChat = () => {
  return useMutation(DISCONECT_ADMIN_FROM_CHAT);
};

export const useReadChatMutation = (options) => {
  return useMutation(READ_CHAT_MUTATION, {
    ...options,
  });
};

export const useDeleteAdminMutation = (options) => {
  return useMutation(ADMIN_DELETE_MUTATION, {
    ...options,
  });
};
