import AddAdmin from '../pages/AddAdmin/AddAdmin';
import AdminsPage from '../pages/Admins/Admins';
import BanedUsersPage from '../pages/BanedUsers/BanedUsers';
import ChatPage from '../pages/Chat/Chat';
import ChatsPage from '../pages/Chats/Chats';
import ComplaintPage from '../pages/Complaint/Complaint';
import ComplaintsPage from '../pages/Complaints/Complaints';
import HomePage from '../pages/Home/Home';
import LoginPage from '../pages/Login/Login';
import Metrics from '../pages/Metrics/Metrics';
import ProjectPage from '../pages/Project/Project';
import ProjectsPage from '../pages/Projects/Projects';
import UnpickedChatsPage from '../pages/UnpickedChats/UnpickedChats';
import UserPage from '../pages/User/User';
import UsersPage from '../pages/Users/Users';
import { ROUTES } from './constants';

const routes = [
  { path: ROUTES.login, component: LoginPage },
  { path: ROUTES.metrics, component: Metrics, isPrivate: true },
  { path: ROUTES.addAdmin, component: AddAdmin, isPrivate: true },
  { path: ROUTES.users, component: UsersPage, isPrivate: true },
  { path: ROUTES.banedUsers, component: BanedUsersPage, isPrivate: true },
  { path: ROUTES.projects, component: ProjectsPage, isPrivate: true },
  { path: ROUTES.unpickedChats, component: UnpickedChatsPage, isPrivate: true },
  { path: ROUTES.chats, component: ChatsPage, isPrivate: true },
  { path: ROUTES.complaints, component: ComplaintsPage, isPrivate: true },
  { path: ROUTES.chat, component: ChatPage, isPrivate: true },
  { path: ROUTES.user, component: UserPage, isPrivate: true },
  { path: ROUTES.project, component: ProjectPage, isPrivate: true },
  { path: ROUTES.complaint, component: ComplaintPage, isPrivate: true },
  { path: ROUTES.home, component: HomePage, isPrivate: true },
  { path: ROUTES.admin, component: AdminsPage, isPrivate: true },
];

export default routes;
