import { gql } from '@apollo/client';

export const NEW_MESSAGE_IN_CHAT_SUBSCRIPTION = gql`
  subscription newMessageInChat($chatId: ID!) {
    newMessageInChat(chatId: $chatId) {
      message {
        userId
        id
        chatId
        createdAt
        readAt
        text
        files {
          id
          messageId
          path
        }
      }
    }
  }
`;

export const NEW_MESSAGE_SUBSCRIPTION = gql`
  subscription newMessageInChat($chatId: ID!) {
    newMessageInChat(chatId: $chatId) {
      message {
        userId
        id
        chatId
        readAt
        createdAt
        text
        files {
          id
          messageId
          path
        }
      }
    }
  }
`;

export const NEW_MESSAGE_IN_ALL_CHATS_SUBSCRIPTION = gql`
  subscription newMessage($userId: ID!) {
    newMessage(userId: $userId) {
      message {
        userId
        id
        chatId
        readAt
        createdAt
        text
        files {
          id
          messageId
          path
        }
      }
    }
  }
`;

export const UNREAD_CHATS_COUNT_SUBSCRIPTION = gql`
  subscription unreadChatCount($userId: ID!) {
    unreadChatCount(userId: $userId) {
      count
    }
  }
`;
