import { makeStyles } from '@mui/styles';

import pallete from '../../theme/pallete';

const useStyles = () =>
  makeStyles(() => ({
    cardWrapper: {
      borderRadius: 4,
      paddingTop: 20,
      paddingRight: 20,
      boxShadow:
        '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    },
    chatContentWrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
    chatListLink: {
      textDecoration: 'underline',
      marginBottom: 20,
      cursor: 'pointer',
    },
    rowWrapper: {
      padding: 20,
      borderBottom: `1px solid ${pallete.black}20`,
      display: 'flex',
    },
    rowList: {
      flexDirection: 'column',
    },
    listWrapper: {
      padding: '0 15px',
      marginBottom: 20,
      boxShadow:
        '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    },
    chatMainWrapper: {
      width: 600,
      borderLeft: pallete.cardBorder,
    },
    chatWrapper: {
      width: 500,
      height: 'calc(100vh - 440px)',
      overflowY: 'auto',
      paddingLeft: 15,
      paddingRight: 15,
    },
    input: {
      width: 380,
    },
    fileInput: {
      display: 'none',
    },
    btn: {
      width: 40,
      minWidth: '40px!important',
      borderRadius: '50%!important',
      height: 40,
      '& span': {
        marginLeft: 0,
      },
      marginLeft: '15px!important',
    },
    logoutBtn: {
      alignSelf: 'center',
      width: 200,
    },
    inputWrapper: {
      width: 500,
      display: 'flex',
      justifyContent: 'space-between',
      borderTop: `1px solid ${pallete.black}50`,
      marginTop: 10,
      paddingTop: 10,
      alignItems: 'center',
    },
  }))();

export default useStyles;
