import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import Analytics from '@mui/icons-material/Analytics';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ChatIcon from '@mui/icons-material/Chat';
import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonAddDisabledIcon from '@mui/icons-material/PersonAddDisabled';

export const API_HOST = process.env.API_HOST;
export const SOCKET_HOST = process.env.SOCKET_HOST;
export const BASE_URL = process.env.BASE_URL;
export const ROUTES = {
  // LOGIN
  login: '/login',
  //DASHBOARD
  home: '/',
  users: '/users',
  banedUsers: '/baned-users',
  addAdmin: '/add-admin',
  user: '/users/:id',
  projects: '/projects',
  project: '/projects/:id',
  unpickedChats: '/unpicked-chats',
  chats: '/chats',
  chat: '/chats/:id',
  complaints: '/complaints',
  complaint: '/complaints/:id',
  metrics: '/metrics',
  admin: '/admin',
};

export const navigations = [
  {
    title: 'Активные пользователи',
    route: ROUTES.users,
    Icon: PeopleAltIcon,
  },
  {
    title: 'Заблокированные пользователи',
    route: ROUTES.banedUsers,
    Icon: PersonAddDisabledIcon,
  },
  {
    title: 'Добавить администратора',
    route: ROUTES.addAdmin,
    Icon: PersonAddIcon,
  },
  {
    title: 'Проекты',
    route: ROUTES.projects,
    Icon: BusinessCenterIcon,
  },
  {
    title: 'Невыбранные чаты',
    route: ROUTES.unpickedChats,
    Icon: ChatIcon,
  },
  {
    title: 'Мои чаты',
    route: ROUTES.chats,
    Icon: MarkChatReadIcon,
    chatsCount: true,
  },
  {
    title: 'Метрики',
    route: ROUTES.metrics,
    Icon: Analytics,
  },
  {
    title: 'Жалобы',
    route: ROUTES.complaints,
    Icon: AnnouncementIcon,
  },
  {
    title: 'Администраторы',
    route: ROUTES.admin,
    Icon: AdminPanelSettingsIcon,
    isSuperAdminPage: true,
  },
];

export const ERRORS = {
  INVALID_LOGIN_OR_PASSWORD: 'Invalid login or password',
};

export const TABLE_LIMITS_ARRAY_OPTIONS = [5, 10, 15];

export const USERS_TABLE_HEAD_OPTIONS = [
  { id: 'avatar', label: 'Фото', minWidth: 30 },
  { id: 'name', label: 'Имя', minWidth: 170 },
  {
    id: 'projectCount',
    label: 'Количество проектов',
    minWidth: 170,
  },
  {
    id: 'complaintCount',
    label: 'Количество жалоб',
    minWidth: 170,
  },
  {
    id: 'status',
    label: 'Статус',
    minWidth: 170,
  },
];

export const ADMINS_TABLE_HEAD_OPTIONS = [
  { id: 'avatar', label: 'Фото', minWidth: 30 },
  { id: 'name', label: 'Имя', minWidth: 170 },
  { id: 'login', label: 'Логин', minWidth: 170 },
  { id: 'action', label: 'Удаление', minWidth: 170 },
];

export const PROJECTS_TABLE_HEAD_OPTIONS = [
  { id: 'name', label: 'Название', minWidth: 170 },
  { id: 'ownerName', label: 'Владелец', minWidth: 170 },
  { id: 'type', label: 'Тип', minWidth: 170 },
  {
    id: 'complaintCount',
    label: 'Количество жалоб',
    minWidth: 170,
  },
  {
    id: 'verified',
    label: 'Статус',
    minWidth: 170,
  },
];

export const PROJECTS_VERIFIED_SELECT_OPTIONS = [
  { value: 'all', label: 'Все' },
  { value: 'verified', label: 'Подтвердженные' },
  { value: 'no_verified', label: 'Не подтвердженные' },
  { value: 'on_moderation', label: 'На модерации' },
];

export const COMPLAINTS_VERIFIED_SELECT_OPTIONS = [
  { value: 'all', label: 'Все' },
  { value: 'user', label: 'Жалобы на пользователей' },
  { value: 'project', label: 'Жалобы на проекты' },
];

export const UNPICKED_CHATS_TABLE_HEAD_OPTIONS = [
  { id: 'projectName', label: 'Проект', minWidth: 170 },
  { id: 'userName1', label: 'Имя пользователя № 1', minWidth: 170 },
  { id: 'userName2', label: 'Имя пользователя № 2', minWidth: 170 },
  { id: 'lastMessage', label: 'Последнее сообщение', minWidth: 170 },
  { id: 'action', label: 'Присоединение к чату', minWidth: 170 },
];

export const CHATS_TABLE_HEAD_OPTIONS = [
  { id: 'projectName', label: 'Проект', minWidth: 170 },
  { id: 'userName1', label: 'Имя пользователя № 1', minWidth: 170 },
  { id: 'userName2', label: 'Имя пользователя № 2', minWidth: 170 },
  { id: 'lastMessage', label: 'Последнее сообщение', minWidth: 170 },
  { id: 'countUnreadMessage', label: 'Непрочитанных сообщений', minWidth: 170 },
];

export const COMPLAINTS_TABLE_HEAD_OPTIONS = [
  { id: 'ownerName', label: 'Автор жалобы', minWidth: 170 },
  { id: 'type', label: 'Вид жалобы', minWidth: 170 },
  { id: 'typeValueName', label: 'На кого жалоба', minWidth: 170 },
  { id: 'resaonValue', label: 'Суть жалобы', minWidth: 170 },
];
