import { makeStyles } from '@mui/styles';

import pallete from '../../theme/pallete';

const useStyles = () =>
  makeStyles(() => ({
    cardWrapper: {
      borderRadius: 4,
      boxShadow: pallete.cardShadow,
    },
    userListLink: {
      textDecoration: 'underline',
      marginBottom: 20,
      cursor: 'pointer',
    },
    btnWrapper: {
      display: 'flex',
      marginLeft: 20,
      marginRight: 20,
    },
    btn: {
      width: 250,
    },
  }))();

export default useStyles;
