import { makeStyles } from '@mui/styles';

import fontSizes from '../../theme/fontSizes';
import pallete from '../../theme/pallete';

const useStyles = ({ you }) =>
  makeStyles(() => ({
    mainMessageWrapper: {
      display: 'flex',
      justifyContent: you ? 'flex-end' : 'flex-start',
    },
    messageContentWrapper: {
      borderRadius: you ? '0 16px' : '16px 0',
      backgroundColor: you ? '#2C98F0' : '#F0F2F3',
      padding: '6px 12px',
    },
    messageText: {
      color: you ? pallete.white : pallete.messageBg,
    },
    messageImg: {
      cursor: 'pointer',
      '& img': {
        width: 'auto',
        height: '100px',
        borderRadius: 10,
        marginTop: 6,
      },
    },
    messageFile: {
      cursor: 'pointer',
    },
    messageDesc: {
      fontSize: fontSizes.extra10,
      marginTop: 4,
      color: pallete.black,
      marginBottom: 16,
      display: 'flex',
      alignItems: 'center',
      justifyContent: you ? 'flex-end' : 'flex-start',
    },
    messageReadStatus: {
      height: 12,
      marginLeft: 5,
      width: 12,
    },
    statusIconWrapper: {
      display: 'flex',
      justifyContent: you ? 'flex-end' : 'flex-start',
    },
    statusIcon: {
      height: 12,
      width: 12,
      marginTop: 4,
      marginBottom: 16,
    },
  }))();

export default useStyles;
